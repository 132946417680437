import React from 'react'
import ReactDOM from 'react-dom/client'
import { initializeApp } from '@firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

import './index.css'
import App from './App'

const firebaseConfig = {
  apiKey: 'AIzaSyCLLJ30ekGoVSi-t9stkdgKZ6cDTnHW5gU',
  authDomain: 'ref-report.firebaseapp.com',
  projectId: 'ref-report',
  storageBucket: 'ref-report.appspot.com',
  messagingSenderId: '148975740031',
  appId: '1:148975740031:web:0541a0e1729e3fe1c7cb4a'
}

const app = initializeApp(firebaseConfig)
getAnalytics(app)
getPerformance(app)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
