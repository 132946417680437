export function setLocal (key: string, value: any): void {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getLocal<T> (key: string): T | undefined {
  try {
    const stored = localStorage.getItem(key)
    if (stored === 'undefined' || !stored) {
      return undefined
    }
    return JSON.parse(stored) as T
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export function removeLocal (key: string): void {
  localStorage.removeItem(key)
}

export function clearLocal () {
  localStorage.clear()
}
