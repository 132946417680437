import { BasicTextField } from 'components/common'
import React from 'react'
import MaskedInput from 'react-text-mask'

interface ClockTimeInputProps {
  style?: React.CSSProperties
  className?: string
  value?: string | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  error?: boolean
}

const ClockTimeInput: React.FC<ClockTimeInputProps> = ({ style, className, value, onChange, error }) => {
  return (
    <MaskedInput
      style={style}
      mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
      type="tel"
      className={className}
      value={value}
      placeholder='00:00'
      onChange={onChange}
      guide
      render={(ref, props) => (
        <BasicTextField
          inputRef={ref}
          required
          error={error}
          label='Clock time (mm:ss)'
          {...props}
        />
      )}
    />
  )
}
export default ClockTimeInput
