import { getMASLPlayerList } from 'utils/api/masl-api'
import { useApi } from 'utils/hooks/useApi'
import { type MASLPlayerInfo, MASL_PLAYERS_SESSION_KEY } from 'utils/constants/masl'
import { getSession, setSession } from 'utils/sessionStorage'

export const useMASLPlayers = () => {
  const { data, error, loading } = useApi(async () => {
    const storedPlayers = getSession<MASLPlayerInfo[]>(MASL_PLAYERS_SESSION_KEY)
    if (storedPlayers) {
      return { players: storedPlayers }
    }

    const players = await getMASLPlayerList()
    setSession(MASL_PLAYERS_SESSION_KEY, players)
    return { players }
  }, [])

  return { data, error, loading }
}
