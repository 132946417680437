import { useContext } from 'react'
import { AuthContext, type AuthContextResult } from 'utils/context/AuthContext'

interface AuthState extends AuthContextResult {
  authenticated: boolean
}

export const useAuthState = (): AuthState => {
  const { firebaseUser, user, loading } = useContext(AuthContext)

  if (firebaseUser != null) {
    return {
      authenticated: firebaseUser !== null,
      user,
      firebaseUser,
      loading
    }
  } else {
    return { authenticated: false, user: undefined, firebaseUser: null, loading }
  }
}
