import React from 'react'

import ManageAccount from 'components/account/ManageAccount'
import { RequireAuth } from 'components/auth/RequireAuth'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import PageTitle from 'components/common/PageTitle'

const Account: React.FC = () => {
  return (
    <RequireAuth>
      <PageTitle title='Account Settings' />
      <DrawerNavigation>
        <ManageAccount />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default Account
