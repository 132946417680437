import React, { useEffect, useState } from 'react'
import { getAuth, type User as FirebaseUser } from 'firebase/auth'
import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

import { AuthContext } from 'utils/context/AuthContext'
import { type League } from 'utils/constants/constants'

export enum Permission {
  ADMIN = 'admin',
  EDITOR = 'EDITOR',
  VIEWER = 'viewer'
}

export interface User {
  id: string
  email: string
  leagues: League[]
  permissions: Record<League | string, Permission[]>
  firstName: string
}

interface AuthProviderProps {
  children?: React.ReactNode
}

export async function getUser (firebaseUid: string | undefined): Promise<User | undefined> {
  if (!firebaseUid) {
    return undefined
  }
  const firestore = getFirestore()
  const userDoc = await getDoc(doc(firestore, 'users', firebaseUid))
  const user = { ...userDoc.data(), id: firebaseUid }
  return user as User
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const auth = getAuth()
  const analytics = getAnalytics()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUserProperties(analytics, {
        user_id: firebaseUser?.uid,
        user_email: firebaseUser?.email
      })
      setFirebaseUser(firebaseUser)
      getUser(firebaseUser?.uid).then((user) => {
        setUser(user)
        setLoading(false)
      }).catch(() => {
        setUser(undefined)
        setLoading(false)
      })
    })

    return unsubscribe
  }, [auth, analytics])

  return (
    <AuthContext.Provider value={{ firebaseUser, user, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
