import { type League } from 'utils/constants/constants'
import { listReports } from 'utils/api/api'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useApi } from 'utils/hooks/useApi'
import { logSearch } from 'utils/analytics'
import { type DateFilter } from 'utils/constants/masl'

export const useLeagueReports = (options: { league: League, page: number, count: number, searchQuery?: string, dateFilter?: DateFilter }, deps: any[]) => {
  const { user } = useAuthState()
  const { data, error, loading } = useApi(async () => {
    logSearch(options.searchQuery, options)
    if (!user || !options.league) {
      return undefined
    }
    return await listReports(options.league, user?.id, options.page, options.count, options.searchQuery, options.dateFilter)
  }, [user?.id, ...deps])

  return { reports: data?.reports, length: data?.length, error, loading }
}
