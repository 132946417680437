import { Delete } from '@mui/icons-material'
import { Divider, Link, Typography, CircularProgress, Box, Button } from '@mui/material'
import { RequiredTextField } from 'components/common'
import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import React, { useMemo, useState } from 'react'
import { addLeagueDocument, deleteLeagueDocument } from 'utils/api/api'

import { type League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useLeagueDocuments } from 'utils/hooks/useLeagueDocuments'
import { Permission } from 'utils/providers/AuthProvider'

interface LeagueDocumentsProps {
  league: League
}

const LeagueDocuments: React.FC<LeagueDocumentsProps> = ({ league }) => {
  const { data, loading, trigger } = useLeagueDocuments(league)
  const { user } = useAuthState()
  const [documentName, setDocumentName] = useState<string>('')
  const [documentUri, setDocumentUri] = useState<string>('')
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)

  const isAdmin = useMemo(() => user?.permissions[league].includes(Permission.ADMIN), [league, user?.permissions])

  function showDeleteConfirm (name: string, uri: string) {
    setDocumentName(name)
    setDocumentUri(uri)
    setShowConfirmDialog(true)
  }

  function deleteDocument (name: string, uri: string) {
    setDialogLoading(true)
    deleteLeagueDocument(league, name, uri).then(() => {
      trigger()
      setShowConfirmDialog(false)
      setDialogLoading(false)
    }).catch((e) => {
      console.error(e)
    })
  }

  function addDocument (name: string, uri: string) {
    setDialogLoading(true)
    addLeagueDocument(league, name, uri).then(() => {
      trigger()
      setShowAddDialog(false)
      setDialogLoading(false)
    }).catch((e) => {
      console.error(e)
    })
  }

  return (
    <>
      {data?.documents?.map((doc) => (
        <>
          <Box margin='0.5em' display='flex' alignItems='center' gap='0.5em' key={doc.uri} flexWrap='wrap'>
            {isAdmin && <Button onClick={() => { showDeleteConfirm(doc.name, doc.uri) }}><Delete color='error' /></Button>}
            <Link href={doc.uri} rel='noopener noreferrer' target='_blank'>{doc.name}</Link>
          </Box>
          <Divider />
        </>
      ))}
      {!loading && !data?.documents && <Typography variant='h6' marginTop='1em'>No documents</Typography>}
      {loading && <Box marginTop='1em'><CircularProgress /></Box>}
      {isAdmin && (
        <Box margin={'auto'} textAlign={'center'} marginTop={'1em'}>
          <Button onClick={() => { setShowAddDialog(true) }}>Add Document</Button>
        </Box>
      )}
      <ConfirmDialog
        open={showConfirmDialog}
        title={'Delete document'}
        body={(
          <>Confirm you want to delete <Link href={documentUri} rel='noopener noreferrer' target='_blank'>{documentName}</Link></>
        )}
        onOk={() => {
          deleteDocument(documentName, documentUri)
        }}
        onCancel={() => {
          setDocumentName('')
          setDocumentUri('')
          setShowConfirmDialog(false)
        }}
        confirmColor='error'
        loading={dialogLoading}
      />
      <ConfirmDialog
        open={showAddDialog}
        title={'Add a document'}
        body={(
          <Box display='flex' gap='1em' flexDirection='column'>
            <RequiredTextField label='Document Name' placeholder='Document Name' onChange={(event) => { setDocumentName(event.target.value) }} fullWidth />
            <RequiredTextField label='Document URL' placeholder='Document URL' onChange={(event) => { setDocumentUri(event.target.value) }} fullWidth />
          </Box>
        )}
        onOk={() => {
          addDocument(documentName, documentUri)
        }}
        onCancel={() => {
          setShowAddDialog(false)
        }}
        confirmText='Add'
        loading={dialogLoading}
      />
    </>
  )
}
export default LeagueDocuments
