import { League, type BaseSchema } from 'utils/constants/constants'

export interface MASLReportSchema extends BaseSchema {
  id?: string
  league: League.MASL | League.M2 | League.M3 | undefined
  date: string
  homeTeam: MASLTeam | string
  homeScore: string
  author: string
  awayTeam: MASLTeam | string
  awayScore: string
  location: string
  seniorReferee: string
  referee: string
  thirdReferee?: string
  assistantReferee: string
  fourthOfficial: string
  otherOfficials: string
  firstHalfStart: string
  firstHalfStartExplanation: string
  secondHalfStart: string
  secondHalfStartExplanation: string
  gameEndTime: string
  penalties?: Penalty[]
  reviews?: Review[]
  editable: boolean
  notes: string
  worksheetImageUrl?: string | null
  homeRosterImageUrl?: string
  awayRosterImageUrl?: string
  shootoutOccurred?: boolean
  homeShootoutScore?: string
  awayShootoutScore?: string
  seniorRefereeDistance?: string
  refereeDistance?: string
  thirdRefereeDistance?: string
  season?: string
  gameNumber?: string
  overtimeOccurred?: boolean
  gameTime?: string
  leagueStandardsQuestions?: MASLQuestion
  gameLengthTime?: string
  extraTimePlayed?: boolean
  gameType?: GameType
}

export type MASLQuestion = Record<string, { answer?: string, note?: string } | undefined>

export interface Penalty {
  id: string
  name?: string
  number?: string
  team?: MASLTeam | string
  period?: Period
  time?: string
  explanation?: string
  code?: string
  category?: string
  playerType?: MASLPlayerType
  fine?: string
}

export interface Review {
  id: string
  initiator?: Initiator
  team?: MASLTeam | string
  period?: Period
  time?: string
  category?: string
  code?: string
}

export enum MASLTeam {
  BALTIMORE = 'Baltimore',
  CHIHUAHUA = 'Chihuahua',
  DALLAS = 'Dallas',
  EMPIRE = 'Empire',
  HARRISBURG = 'Harrisburg',
  KANSAS_CITY = 'Kansas City',
  MILWAUKEE = 'Milwaukee',
  MONTERREY = 'Monterrey',
  SAN_DIEGO = 'San Diego',
  ST_LOUIS = 'St Louis',
  TACOMA = 'Tacoma',
  TEXAS = 'Texas',
  UTICA = 'Utica',
}

export enum M2Team {
  AMARILLO = 'Amarillo Bombers',
  BALTIMORE = 'Baltimore Arsenal',
  EL_PASO = 'El Paso Rhinos FC',
  IOWA_DEMON_HAWKS = 'Iowa Demon Hawks',
  IOWA_RAPTORS = 'Iowa Raptors FC',
  KANSAS = 'Kansas Bandits',
  KRAJISNIK = 'United Elite Krajisnik FC',
  MUSKEGON = 'Muskegon Risers',
  NEW_MEXICO = 'New Mexico Runners',
  ROCHESTER = 'Rochester Lancers',
  ST_LOUIS = 'St. Louis Ambush 2',
  WICHITA = 'Wichita Wings',
  ORLANDO = 'Atletico Orlando'
}

export enum M3Team {
  BALTIMORE = 'Baltimore Kings',
  DMV = 'DMV Gunners',
  FREDERICKSBURG = 'Fredericksburg Fire FC',
  N_VIRGINIA = 'Northern Virginia FC',
  PHILADELPHIA = 'Philadelphia Spartans',
  SALISBURY = 'Salisbury Steaks',
  VIRGINIA = 'Virginia Marauders FC'
}

export enum GameType {
  REGULAR = 'Regular',
  EXTRA_TIME_PERIOD = 'ET Period'
}

export enum Initiator {
  COACH_CHALLENGE = 'Coach\'s Challenge',
  REFEREE = 'Referee'
}

export enum Period {
  ONE = 'One',
  TWO = 'Two',
  THREE = 'Three',
  FOUR = 'Four',
  OT = 'OT',
  OT1 = 'OT1',
  OT2 = 'OT2',
  OT3 = 'OT3',
  OT4 = 'OT4',
  OT5 = 'OT5',
  OT6 = 'OT6',
  OT7 = 'OT7',
  OT8 = 'OT8',
  OT9 = 'OT9',
  OT10 = 'OT10',
  SHOOTOUT = 'Shootout'
}

export enum DateFilter {
  PAST = 'past',
  ALL = 'all',
  FUTURE = 'future'
}

export const MASL_TEAMS: MASLTeam[] = [
  MASLTeam.BALTIMORE,
  MASLTeam.CHIHUAHUA,
  MASLTeam.DALLAS,
  MASLTeam.EMPIRE,
  MASLTeam.HARRISBURG,
  MASLTeam.KANSAS_CITY,
  MASLTeam.MILWAUKEE,
  MASLTeam.MONTERREY,
  MASLTeam.SAN_DIEGO,
  MASLTeam.ST_LOUIS,
  MASLTeam.TACOMA,
  MASLTeam.TEXAS,
  MASLTeam.UTICA
]

export const M2_TEAMS: M2Team[] = [
  M2Team.ORLANDO,
  M2Team.AMARILLO,
  M2Team.BALTIMORE,
  M2Team.EL_PASO,
  M2Team.IOWA_DEMON_HAWKS,
  M2Team.IOWA_RAPTORS,
  M2Team.KANSAS,
  M2Team.KRAJISNIK,
  M2Team.MUSKEGON,
  M2Team.NEW_MEXICO,
  M2Team.ROCHESTER,
  M2Team.ST_LOUIS,
  M2Team.WICHITA
]

export const M3_TEAMS: M3Team[] = [
  M3Team.BALTIMORE,
  M3Team.DMV,
  M3Team.FREDERICKSBURG,
  M3Team.N_VIRGINIA,
  M3Team.PHILADELPHIA,
  M3Team.SALISBURY,
  M3Team.VIRGINIA
]

export const periods = [Period.ONE, Period.TWO, Period.THREE, Period.FOUR, Period.OT, Period.SHOOTOUT]

export const extraTimePeriods = [
  Period.ONE,
  Period.OT1,
  Period.OT2,
  Period.OT3,
  Period.OT4,
  Period.OT5,
  Period.OT6,
  Period.OT7,
  Period.OT8,
  Period.OT9,
  Period.OT10
]

export const DEFAULT_MASL_REPORT: MASLReportSchema = {
  id: undefined,
  league: League.MASL,
  date: '',
  homeTeam: MASLTeam.BALTIMORE,
  homeScore: '',
  awayTeam: MASLTeam.BALTIMORE,
  awayScore: '',
  author: '',
  location: '',
  seniorReferee: '',
  referee: '',
  assistantReferee: '',
  fourthOfficial: '',
  otherOfficials: '',
  firstHalfStart: '',
  firstHalfStartExplanation: '',
  secondHalfStart: '',
  secondHalfStartExplanation: '',
  gameEndTime: '',
  penalties: [],
  reviews: [],
  editable: true,
  notes: ''
}

export interface MASLLeague {
  seasons: string[]
}

export const MASL_PLAYERS_SESSION_KEY = 'masl-player-list'

export interface MASLPlayerInfo {
  playerName: string
  division: string
  team: string
  number: string
  position: string
  rank: string
  status: string
  email: string
  birthDate: string
  nickname: string
  pronunciation?: string
  height?: string
  weight?: string
  homeTown?: string
  twitter?: string
  instagram?: string
  addedToTeam?: string
  addedToSystem?: string
}

export const MASL_QUESTIONS: string[] = [
  'Field Properly Marked',
  'Referee Liaison Available',
  'Ambulance (EMT) On Site or On Call',
  'Safe Playing Surface and Dasher Boards/Glass',
  'League Patch on Uniform Sleeve',
  'Trainer and Doctor Present',
  'Operational Scoreboard, Time Clock, Power Play Clock',
  'Name and Number on Uniform',
  'Home Team On-time return Halftime',
  'Visiting Team On-time return Halftime',
  'Referee Locker Room and Towels, Soap, Water',
  'Both Teams & Referees Introduced',
  'Appropriate MASL Game Balls (3 new game balls)',
  'National Anthem(s) Played',
  'Statistician Met 4th Official Post-game',
  'Referee Security Available & Escorted',
  'National Flag(s)',
  'Game Event Timeline in Locker Room',
  'Game Event Timeline Followed'
]

export interface MASLCoach {
  name: string
  team: string
  position: string
}

export const MASL_COACHES: MASLCoach[] = [
  { name: 'Locker, Jeff', team: 'St. Louis', position: 'HC' },
  { name: 'Alberty, Donnie', team: 'St. Louis', position: 'AC' },
  { name: 'Swanner, Jamie', team: 'St. Louis', position: 'AC' },
  { name: 'Bascome, David', team: 'Baltimore', position: 'HC' },
  { name: 'Moura, Sergio', team: 'Baltimore', position: 'AC' },
  { name: 'Neto, Aduato', team: 'Baltimore', position: 'AC' },
  { name: 'Moreira, Hewerton', team: 'Utica', position: 'HC' },
  { name: 'Murphy, Casey', team: 'Utica', position: 'AC' },
  { name: 'Stokic, Stefan', team: 'Kansas City', position: 'HC' },
  { name: 'Belz, Jason', team: 'Kansas City', position: 'Performance C' },
  { name: 'Gordon, Matt', team: 'Kansas City', position: 'AC' },
  { name: 'Horn, Lainey', team: 'Kansas City', position: 'Performance C' },
  { name: 'Martey, Kye', team: 'Kansas City', position: 'AC' },
  { name: 'Mayer, Alan', team: 'Kansas City', position: 'GKC' },
  { name: 'Mayer, Kenny', team: 'Kansas City', position: 'AGKC' },
  { name: 'Borrego, Luis Jaime', team: 'Monterrey', position: 'HC' },
  { name: 'Alemán, Antonio', team: 'Monterrey', position: 'AC' },
  { name: 'Salas, Raúl', team: 'Monterrey', position: 'GKG' },
  { name: 'Terán, Armando', team: 'Monterrey', position: 'AC' },
  { name: 'Healey, Pat', team: 'Harrisburg', position: 'HC' },
  { name: 'Mellor, Tom', team: 'Harrisburg', position: 'AC' },
  { name: 'Pecorari, Antonio "Tatu"', team: 'Texas', position: 'HC' },
  { name: 'Stavrou, Nick', team: 'Texas', position: 'AC' },
  { name: 'Xavier, Edilson "Sagu"', team: 'Texas', position: 'AC' },
  { name: 'Sánchez, Everardo', team: 'Chihuahua', position: 'HC' },
  { name: 'Barragan, Juan', team: 'Chihuahua', position: 'AC' },
  { name: 'Domínguez, Alan', team: 'Chihuahua', position: 'AC' },
  { name: 'Salvagio, Phil', team: 'San Diego', position: 'HC' },
  { name: 'Luna, Chiky', team: 'San Diego', position: 'AC' },
  { name: 'Melendez, Victor', team: 'San Diego', position: 'AC' },
  { name: 'Ortiz, Rene', team: 'San Diego', position: 'AC' },
  { name: 'Becker, Adam', team: 'Tacoma', position: 'AC' },
  { name: 'McNeley, Evan', team: 'Tacoma', position: 'AC' },
  { name: 'Wright, Paul', team: 'Empire', position: 'HC' },
  { name: 'Dougherty, Paul', team: 'Empire', position: 'AC' },
  { name: 'Molina, Jesus', team: 'Empire', position: 'AC' },
  { name: 'Sesay, Israel', team: 'Empire', position: 'AC' },
  { name: 'Oliviero, Giuliano', team: 'Milwaukee', position: 'HC' },
  { name: 'Morrison, Troy', team: 'Milwaukee', position: 'AC' },
  { name: 'Rampa, Tenzin', team: 'Milwaukee', position: 'AC' },
  { name: 'Puskarich, Ed', team: 'Dallas', position: 'HC' },
  { name: 'Couch, Kiley', team: 'Dallas', position: 'AC' },
  { name: 'Woodberry, Terry', team: 'Dallas', position: 'AC' },
  { name: 'Golden, Matt', team: 'Dallas', position: 'AC' }

]

export type MASLLeagueType = League.MASL | League.M2 | League.M3

export const KMI_QUESTION = 'League Reviewable KMIs'

export enum MASLPlayerType {
  Coach = 'Coach',
  Player = 'Player',
  Team = 'Team'
}
