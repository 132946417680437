import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Divider, Typography } from '@mui/material'
import { BasicTextField } from 'components/common'
import React from 'react'
import { League } from 'utils/constants/constants'
import { type MASLQuestion, type MASLReportSchema, MASL_QUESTIONS, KMI_QUESTION } from 'utils/constants/masl'

interface MASLNotesProps {
  league: League
  notes: string
  leagueStandardsQuestions?: MASLQuestion
  seniorRefereeDistance?: string
  refereeDistance?: string
  thirdRefereeDistance?: string
  onStateChange: (key: keyof MASLReportSchema, value: string) => void
  onQuestionChange?: (question: string, answer: string, notes: string) => void
}

const MASLNotes: React.FC<MASLNotesProps> = ({ league, leagueStandardsQuestions, notes, onStateChange, onQuestionChange }) => {
  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      <Typography>Match Notes &amp; Supplementals</Typography>
      <BasicTextField
        value={notes}
        style={{ width: '100%' }}
        placeholder="Any match notes or supplemental reports. Notes are required for any Y17 or Ejection."
        multiline
        rows={4}
        onChange={(event) => { onStateChange('notes', event.target.value) }}
      />
      {league === League.MASL && <Divider>League Standards</Divider>}
      {league === League.MASL && (
        <FormControl fullWidth>
          <FormLabel>{KMI_QUESTION}</FormLabel>
          <RadioGroup
            value={leagueStandardsQuestions?.[KMI_QUESTION]?.answer ?? 'No'}
            onChange={(event) => { onQuestionChange?.(KMI_QUESTION, event.target.value, '') }}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
          {leagueStandardsQuestions?.[KMI_QUESTION]?.answer === 'Yes' && (
            <BasicTextField
              multiline
              onChange={(event) => { onQuestionChange?.(KMI_QUESTION, 'Yes', event.target.value) }}
              label='Explanation'
              value={leagueStandardsQuestions?.[KMI_QUESTION]?.note ?? ''}
              rows={3}
            />
          )}
        </FormControl>
      )}
      {league === League.MASL && MASL_QUESTIONS.map(question => (
        <FormControl key={question} fullWidth>
          <FormLabel>{question}</FormLabel>
          <RadioGroup
            value={leagueStandardsQuestions?.[question]?.answer ?? 'Yes'}
            onChange={(event) => { onQuestionChange?.(question, event.target.value, '') }}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
          {leagueStandardsQuestions?.[question]?.answer === 'No' && (
            <BasicTextField
              multiline
              onChange={(event) => { onQuestionChange?.(question, 'No', event.target.value) }}
              label='Explanation'
              value={leagueStandardsQuestions?.[question]?.note ?? ''}
              rows={3}
            />
          )}
        </FormControl>
      ))}
    </section>
  )
}
export default MASLNotes
