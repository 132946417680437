import React, { useEffect } from 'react'
import { Divider } from '@mui/material'

import { BasicTextField } from 'components/common'
import { type MASLReportSchema } from 'utils/constants/masl'
import { calculateTimeDifference } from 'utils'
import DynamicTimeInput from 'components/common/inputs/DynamicTimeInput'

interface MASLTimesProps {
  firstHalfStart: string
  secondHalfStart: string
  gameEndTime: string
  firstHalfStartExplanation: string
  secondHalfStartExplanation: string
  gameLengthTime?: string
  onStateChange: (key: keyof MASLReportSchema, value: string | null) => void
}

const MASLTimes: React.FC<MASLTimesProps> = ({ onStateChange, ...props }) => {
  useEffect(() => {
    const diff = props.firstHalfStart && props.gameEndTime ? calculateTimeDifference(props.firstHalfStart, props.gameEndTime) : undefined
    if (diff) {
      onStateChange('gameLengthTime', diff)
    } else {
      onStateChange('gameLengthTime', null)
    }
  }, [onStateChange, props.firstHalfStart, props.gameEndTime])

  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <DynamicTimeInput
        value={props.firstHalfStart ?? ''}
        label='Game start time'
        onChange={(e) => { onStateChange('firstHalfStart', e.target.value) }}
        placeholder='12:00 PM'
      />
      <BasicTextField
        value={props.firstHalfStartExplanation ?? ''}
        label='If starting late, why?'
        onChange={(event) => { onStateChange('firstHalfStartExplanation', event.target.value) }}
      />
      <Divider />
      <DynamicTimeInput
        value={props.secondHalfStart?.toUpperCase() ?? ''}
        label='2nd half start time'
        onChange={(e) => { onStateChange('secondHalfStart', e.target.value) }}
        placeholder='12:00 PM'
      />
      <BasicTextField
        value={props.secondHalfStartExplanation}
        label='If starting late, why?'
        onChange={(event) => { onStateChange('secondHalfStartExplanation', event.target.value) }}
      />
      <Divider />
      <DynamicTimeInput
        value={props.gameEndTime?.toUpperCase() ?? ''}
        label='Game end time'
        onChange={(e) => { onStateChange('gameEndTime', e.target.value) }}
        placeholder='12:00 PM'
      />
    </section>
  )
}
export default MASLTimes
