import React, { useState } from 'react'
import { Autocomplete, Backdrop, Button, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { type Dayjs } from 'dayjs'

import { BasicDatePicker, RequiredTextField, Section, Title } from 'components/common'
import { createReport } from 'utils/api/api'
import { type MASLTeam, type MASLReportSchema, type MASLLeague, type M2Team, type MASLLeagueType, GameType } from 'utils/constants/masl'
import { useAuthState } from 'utils/hooks/useAuthState'
import { TeamAutocompleteInput } from 'components/masl/inputs/TeamAutocompleteInput'
import { useLeague } from 'utils/hooks/useLeague'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'
import DynamicTimeInput from 'components/common/inputs/DynamicTimeInput'

export const MASLCreate: React.FC<{ league: MASLLeagueType }> = ({ league }) => {
  const [date, setDate] = useState<string>()
  const [location, setLocation] = useState<string>()
  const [homeTeam, setHomeTeam] = useState<MASLTeam | M2Team | string>()
  const [awayTeam, setAwayTeam] = useState<MASLTeam | M2Team | string>()
  const [gameTime, setGameTime] = useState('')
  const [season, setSeason] = useState<string | null>()
  const [loading, setLoading] = useState(false)
  const [gameNumber, setGameNumber] = useState<string>()
  const [gameType, setGameType] = useState<GameType>(GameType.REGULAR)
  const redirect = useRedirectToHome(league)
  const { user } = useAuthState()
  const { data: leagueData } = useLeague<MASLLeague>(league, [league])

  function handleCreateMatch (): void {
    setLoading(true)
    createReport<MASLReportSchema>(league, { date, location, homeTeam, awayTeam, league, season: (season ?? ''), gameTime, editable: true, author: user?.id, gameNumber, gameType })
      .then(async () => { await redirect() })
      .catch((e) => { console.error(e) })
      .finally(() => { setLoading(false) })
  }

  return (
    <>
      <h1>Create {league.toUpperCase()} Match Report</h1>

      <Divider />

      <Title>Game date</Title>
      <Section>
        <BasicDatePicker
          label='Game date'
          onChange={(value, context) => {
            setDate((value as Dayjs).toDate().toLocaleDateString())
          }}
        />
        <RequiredTextField label='Game Number' onChange={(event) => { setGameNumber(event.target.value) }} error={!gameNumber} />
        <RequiredTextField label='Location' onChange={(event) => { setLocation(event.target.value) }} error={!location} />
        <DynamicTimeInput
          label='Game time'
          placeholder='12:00 PM'
          onChange={(event) => { setGameTime(event.target.value) }}
          error={!gameTime}
          value={gameTime}
        />
      </Section>

      <Divider />

      <Title>Teams</Title>
      <Section>
        <TeamAutocompleteInput role='Home' onChange={(event, value) => { setHomeTeam(value) }} allowCustom error={!homeTeam} league={league} />
      </Section>
      <Section>
        <TeamAutocompleteInput role='Away' onChange={(event, value) => { setAwayTeam(value) }} allowCustom error={!awayTeam} league={league} />
      </Section>
      <Divider />

      <Title>Season</Title>
      <Section>
        <Autocomplete
          value={season}
          options={leagueData?.seasons ?? []}
          onChange={(event, value) => { setSeason(value) }}
          renderInput={(params) => (
            <RequiredTextField {...params} label='Season' error={!season} fullWidth />
          )}
          fullWidth
        />
      </Section>

      <Title>Game type</Title>
      <Section>
        <FormControl fullWidth>
          <FormLabel>Choose game type</FormLabel>
          <RadioGroup
            value={gameType}
            onChange={(event) => { setGameType(event.target.value as GameType) }}
          >
            <FormControlLabel value={GameType.REGULAR} control={<Radio />} label="Regular game" />
            <FormControlLabel value={GameType.EXTRA_TIME_PERIOD} control={<Radio />} label="Extra time period (Formerly: mini-game)" />
          </RadioGroup>
        </FormControl>
      </Section>

      <Divider />

      <Button style={{ marginTop: '1em' }} variant='contained' onClick={handleCreateMatch} disabled={!homeTeam || !awayTeam || !date || !location || !gameTime || !season || !gameNumber || !gameType}>Create report</Button>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
