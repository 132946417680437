import LoadingSpinner from 'components/common/LoadingSpinner'
import React, { type ReactNode } from 'react'
import { Navigate, createSearchParams, useLocation } from 'react-router-dom'

import { useAuthState } from 'utils/hooks/useAuthState'

interface RequireAuthProps {
  children?: ReactNode
  ignoreAuthRedirect?: boolean
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children, ignoreAuthRedirect }) => {
  const auth = useAuthState()
  const location = useLocation()

  return (
    <>
      {auth.loading && <LoadingSpinner />}
      {!auth.loading && !auth.authenticated && <Navigate to={{ pathname: '/auth/sign-in', search: ignoreAuthRedirect ? undefined : createSearchParams({ redirectUrl: location.pathname }).toString() }} replace />}
      {!auth.loading && auth.authenticated && children}
    </>
  )
}
