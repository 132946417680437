import React, { useMemo } from 'react'

import { League } from 'utils/constants/constants'
import { RequireAuth } from 'components/auth/RequireAuth'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/menu/MASLNavigationMenu'
import PageTitle from 'components/common/PageTitle'
import HomeRoot from 'components/common/HomeRoot'
import { MASLHome } from 'components/masl/home/MASLHome'

interface HomeProps {
  league: League.MASL | League.M2 | League.M3 | undefined
}

const Home: React.FC<HomeProps> = ({ league }) => {
  const menu = useMemo(() => {
    if (league === League.MASL || league === League.M2 || league === League.M3) {
      return <MASLNavigationMenu league={league} />
    }

    return <></>
  }, [league])

  return (
    <RequireAuth>
      <PageTitle title={league?.toUpperCase() ?? 'Home'} />
      <DrawerNavigation menu={menu}>
        <HomeRoot league={league}>
          {(league === League.MASL || league === League.M2 || league === League.M3) && <MASLHome league={league} />}
        </HomeRoot>
      </DrawerNavigation>
    </RequireAuth>
  )
}

export default Home
