import { getAnalytics, logEvent } from 'firebase/analytics'

export function logClick (eventDescription?: string, props?: any) {
  const analytics = getAnalytics()
  logEvent(analytics, 'click', {
    event_description: eventDescription,
    ...props
  })
}

export function logSearch (searchQuery?: string, props?: any) {
  const analytics = getAnalytics()
  logEvent(analytics, 'search', {
    search_term: searchQuery,
    ...props
  })
}
