import { useContext } from 'react'

import { type League } from 'utils/constants/constants'
import { ActiveLeagueContext } from 'utils/context/ActiveLeagueContext'

type UseActiveLeagueReturn = [
  activeLeague: League | undefined,
  setActiveLeague: (t: League | undefined) => void
]

export const useActiveLeague = (): UseActiveLeagueReturn => {
  const { activeLeague, setActiveLeague } = useContext(ActiveLeagueContext)

  return [activeLeague, setActiveLeague]
}
