import React, { useMemo } from 'react'

import { Box, Button, Divider, Link, Typography } from '@mui/material'

import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'
import styles from 'components/index/Index.module.scss'

const Landing = () => {
  const SignInButton = useMemo(() => (
    <Box display='flex' gap='0.5em'>
      <Button
        href='/auth/sign-up'
        color='secondary'
      >
        Sign up
      </Button>
      <Button
        href='/auth/sign-in'
        color='secondary'
        variant='contained'
      >
        Log in
      </Button>
    </Box>
  ), [])

  return (
    <>
      <PageTitle title='Digital match reporting' />
      <TopNavigation buttons={[SignInButton]}>
        <article>
          <section className={styles.page}>
            <div className={styles.logoContainer}>
              <img className={styles.heroLogo} alt='RefReport logo' src='/static/logo-green.webp' />
            </div>
            <Typography className={styles.logoSubtitle} variant='h6'>Digital Reporting Solution</Typography>
          </section>
          <Divider />
          <div className={styles.contentSection}>
            <Typography className={styles.body} variant='h5'>
              RefReport is a software solution for sports leagues to easily digitize match reporting.
            </Typography>
          </div>
          <div className={styles.contentSection}>
            <img className={styles.hero} src='/static/landing-demo-hero.webp' />
          </div>
          <Divider />
          <div className={styles.contentSection}>
            <Typography variant='h5'>Key Features:</Typography>
            <ul>
              <li><Typography variant='body1'>Custom complex and simple form fields.</Typography></li>
              <li><Typography variant='body1'>Individual accounts with secure authentication.</Typography></li>
              <li><Typography variant='body1'>Easy user onboarding and administration.</Typography></li>
              <li><Typography variant='body1'>Photo uploading and retrieval.</Typography></li>
              <li><Typography variant='body1'>Announcements and Document upload.</Typography></li>
            </ul>
          </div>
          <div className={styles.contentSection}>
            <Typography variant='h5'>For more information, <Link href='mailto:contact@hooshmandenterprises.com'>contact us</Link>.</Typography>
          </div>
        </article>
      </TopNavigation>
    </>
  )
}

export default Landing
