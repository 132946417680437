import React, { useState, type ReactNode, useCallback, useMemo } from 'react'

import { type League, LAST_LEAGUE_SESSION_KEY } from 'utils/constants/constants'
import { ActiveLeagueContext } from 'utils/context/ActiveLeagueContext'
import { getLocal, setLocal } from 'utils/localStorage'

interface ActiveLeagueProviderProps {
  children: ReactNode
}

const ActiveLeagueProvider: React.FC<ActiveLeagueProviderProps> = ({ children }) => {
  const [globalActiveLeague, setGlobalActiveLeague] = useState<League | undefined>(getLocal<League>(LAST_LEAGUE_SESSION_KEY))

  const setActiveLeague = useCallback((t: League | undefined) => {
    setGlobalActiveLeague(t)
    setLocal(LAST_LEAGUE_SESSION_KEY, t)
  }, [])

  const activeLeague = useMemo(() => {
    return globalActiveLeague
  }, [globalActiveLeague])

  return (
    <ActiveLeagueContext.Provider value={{ activeLeague, setActiveLeague }}>
      {children}
    </ActiveLeagueContext.Provider>
  )
}
export default ActiveLeagueProvider
