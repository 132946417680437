import { type League } from 'utils/constants/constants'
import { getLeague } from 'utils/api/api'
import { useApi } from 'utils/hooks/useApi'

export const useLeague = <T>(league: League, deps?: any[]): { data: T | undefined, error?: Error, loading: boolean } => {
  const { data, error, loading } = useApi(async () => {
    if (!league) {
      return undefined
    }
    return await getLeague<T>(league)
  }, (deps ?? []))

  return { data, error, loading }
}
