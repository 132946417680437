import React from 'react'

import Landing from 'components/index/Index'

const Index: React.FC = () => {
  return (
    <Landing />
  )
}

export default Index
