import { Divider, Link } from '@mui/material'
import React from 'react'

import styles from './Footer.module.scss'

const Footer: React.FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
  return (
    <footer className={`${styles.footer as string}${fullWidth ? 'Full' : ''}`}>
      <Divider />
      <section className={styles.links}>
        <span>&copy; {new Date().getFullYear()} <Link href='https://hooshmandenterprises.com' target='_blank' rel="noreferrer">Hooshmand Enterprises LLC</Link></span>
        <Link href='/terms-conditions.html'>Terms & Conditions</Link>
        <Link href='/privacy-policy.html'>Privacy policy</Link>
      </section>
    </footer>
  )
}
export default Footer
