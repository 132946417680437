import React, { useState, type SyntheticEvent } from 'react'
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  type AutocompleteChangeReason
} from '@mui/material'

import { RequiredTextField } from 'components/common'
import { type MASLTeam, MASL_TEAMS, M2_TEAMS } from 'utils/constants/masl'
import { League } from 'utils/constants/constants'

interface TeamAutocompleteInputProps {
  role: 'Home' | 'Away'
  value?: MASLTeam
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: MASLTeam | string,
    reason: AutocompleteChangeReason
  ) => void
  disabled?: boolean
  allowCustom?: boolean
  error?: boolean
  league: League.MASL | League.M2 | League.M3
}

export const TeamAutocompleteInput: React.FC<TeamAutocompleteInputProps> = ({ role, value, onChange, disabled, allowCustom, error, league }) => {
  const [customInput, setCustomInput] = useState<string>('')

  function handleOnInputChange (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) {
    setCustomInput(value)
  }

  return (
    <Autocomplete
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      options={allowCustom ? [...(league === League.MASL ? MASL_TEAMS : M2_TEAMS), customInput] : MASL_TEAMS}
      renderInput={(params) => (
        <RequiredTextField {...params} label={`${role} Team`} disabled={disabled} error={error} />
      )}
      disableClearable
      disabled={disabled}
      freeSolo={allowCustom}
    />
  )
}
