import { createContext } from 'react'

import { type League } from 'utils/constants/constants'

interface ActiveLeagueContextProps {
  activeLeague: League | undefined
  setActiveLeague: (t: League | undefined) => void
}

export const ActiveLeagueContext = createContext<ActiveLeagueContextProps>({
  activeLeague: undefined,
  setActiveLeague: () => {}
})
