import React, { useState, type SyntheticEvent, useMemo, useEffect } from 'react'
import {
  Autocomplete,
  type AutocompleteInputChangeReason,
  type AutocompleteChangeReason
} from '@mui/material'

import { RequiredTextField } from 'components/common'
import { type League } from 'utils/constants/constants'
import { useMASLPlayers } from 'utils/hooks/masl/useMASLPlayers'
import { useDebounce } from 'use-debounce'
import FuzzySearch from 'fuzzy-search'
import { type MASLPlayerInfo } from 'utils/constants/masl'

interface PlayerAutocompleteInputProps {
  defaultValue?: string
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: string | Partial<MASLPlayerInfo>,
    reason: AutocompleteChangeReason
  ) => void
  disabled?: boolean
  error?: boolean
  league: League.MASL
  className?: string
}

export const PlayerAutocompleteInput: React.FC<PlayerAutocompleteInputProps> = ({ className, defaultValue, onChange, disabled, error, league }) => {
  const [options, setOptions] = useState<Array<Partial<MASLPlayerInfo>>>([])
  const { data, loading } = useMASLPlayers()
  const [searchText, setSearchText] = useDebounce<string>(defaultValue ?? '', 200)

  const players = useMemo(() => (data?.players ?? []), [data?.players])

  function handleOnInputChange (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) {
    setSearchText(value)
  }

  useEffect(() => {
    if (searchText !== '') {
      const searcher = new FuzzySearch<MASLPlayerInfo>(players, ['playerName', 'team'], { caseSensitive: false, sort: true })
      setOptions([
        ...searcher.search(searchText).slice(0, 5),
        { playerName: searchText, team: 'Unlisted Player' }
      ])
    } else {
      setOptions([{ playerName: searchText, team: 'Unlisted Player' }])
    }
  }, [searchText, players])

  return (
    <Autocomplete
      className={className}
      filterOptions={(x) => x}
      defaultValue={{ playerName: defaultValue }}
      onChange={onChange}
      onInputChange={handleOnInputChange}
      getOptionLabel={(option: string | Partial<MASLPlayerInfo>) => typeof option === 'string' ? option : (option?.playerName ?? '')}
      groupBy={(option) => option?.team ?? ''}
      options={options}
      renderInput={(params) => (
        <RequiredTextField {...params} label='Player name' placeholder='Last name, First name' disabled={disabled} error={error} />
      )}
      disableClearable
      disabled={disabled}
      loading={loading}
      freeSolo
    />
  )
}
