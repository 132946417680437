import { useApi } from 'utils/hooks/useApi'
import { getLeagueAnnouncements } from 'utils/api/api'
import { type League } from 'utils/constants/constants'

export const useLeagueAnnouncements = (league: League) => {
  const result = useApi(async () => {
    const announcements = await getLeagueAnnouncements(league)
    return { announcements }
  }, [league])

  return result
}
