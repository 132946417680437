import React, { type CSSProperties, type SyntheticEvent } from 'react'
import { Autocomplete, type AutocompleteChangeReason } from '@mui/material'

import { RequiredTextField } from 'components/common'

export const videoReviews = [
  { code: '1a', description: 'Foul only is called: when the referee calls only a foul, the coach may challenge that a blue card should have been given.' },
  { code: '1b', description: 'Blue card is given: when the referee issues a blue card power play penalty, the coach may challenge that a blue card was given incorrectly.' },
  { code: '1c', description: 'Penalty Area: incidents resulting in a PK incorrectly awarded OR a PK offense not penalized.' },
  { code: '1d', description: 'Shootout: verification of whether the required criteria of shootout rule 14.7 was met.' },
  { code: '1e', description: 'Faking or Embellishing: a player\'s action which lead to a blue card given.' },
  { code: '2', description: 'Possible Direct Red Card' },
  { code: '3a', description: 'Fouls Not Called: Where the attacking team gains possession as a result of a clear and obvious foul in the attacking possession phase, that directly led to a goal being scored (prior to a possession change). APP definition related to fouls not called is when the attacking team advances with the ball toward their opponents\' goal. In scenarios where there is a possible foul but the attacking team plays a long period of "keep ball" possession, this is not a reviewable situation.' },
  { code: '3b', description: 'Fouls Not Called: A foul not called that where a blue card should have been given.' },
  { code: '4', description: 'Rules Errors (that have a direct impact on the outcome of the game)' },
  { code: '5', description: 'Goal / No goal: related to whether the ball wholly crossed the goal line, prior to the end of the period' },
  { code: 'IC', description: 'Invalid Challenge flag thrown. No Review took place.' }
]

export type VRSituation = typeof videoReviews[number]

interface PenaltyAutocompleteInputProps {
  style?: CSSProperties
  value?: VRSituation
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: {
      description: string
      code: string
    },
    reason: AutocompleteChangeReason
  ) => void
  disabled?: boolean
  error?: boolean
}

export const VRAutocompleteInput: React.FC<PenaltyAutocompleteInputProps> = ({ value, onChange, disabled, style, error }) => {
  return (
    <Autocomplete
      value={value}
      style={{ width: '100%' }}
      onChange={onChange}
      options={videoReviews}
      getOptionLabel={(option) => `${option.code} - ${option.description}`}
      renderInput={(params) => (
        <RequiredTextField style={style} {...params} label='VR Situation' disabled={disabled} error={error} />
      )}
      disableClearable
      disabled={disabled}
    />
  )
}
