import { LoadingButton } from '@mui/lab'
import { Autocomplete, Divider, Input, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'

import { type League } from 'utils/constants/constants'
import { type MASLLeague, type MASLReportSchema } from 'utils/constants/masl'
import { bulkCreateReports } from 'utils/api/api'
import { useAuthState } from 'utils/hooks/useAuthState'
import { readFile } from 'utils/csv'
import { CSVSupportLink, RequiredTextField, Section } from 'components/common'
import { useLeague } from 'utils/hooks/useLeague'
import LoadingDialog from 'components/common/modal/LoadingDialog'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'

const HEADER_ROW: Array<keyof MASLReportSchema> = ['gameNumber', 'date', 'gameTime', 'homeTeam', 'awayTeam', 'location']

interface ImportProps {
  league: League
}

export const MASLImport: React.FC<ImportProps> = ({ league }) => {
  const { user } = useAuthState()
  const { data: leagueData, loading: dataLoading } = useLeague<MASLLeague>(league, [league])
  const redirect = useRedirectToHome()

  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState<string>()
  const [season, setSeason] = useState<string | null>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  async function handleParseClick () {
    setLoading(true)
    const file = inputRef.current?.files?.[0]

    if (!season) {
      setError(true)
      setErrorText('Missing season.')
      return
    } else {
      setError(false)
    }

    if (!file || !user?.id) {
      setError(true)
      setErrorText('Missing input file.')
      return
    } else {
      setError(false)
    }

    const { results: reports, errors } = await readFile<MASLReportSchema>(file, HEADER_ROW)

    console.error(errors)

    if (errors.length > 0) {
      setError(true)
      setErrorText(errors.toString())
    } else {
      setError(false)
    }

    await bulkCreateReports<MASLReportSchema>(user?.id, league, reports.map(r => ({ ...r, season, editable: true })))
    setLoading(false)
    await redirect()
  }

  return (
    <>
      <section>
        <section>
          <Typography variant='h5'>
            Your <CSVSupportLink /> must have columns titled:<br/>
          </Typography>
          <table style={{ border: '1px solid darkgray', borderCollapse: 'collapse', marginTop: 16 }}>
            <tbody>
              <tr>
                {HEADER_ROW.map((value, index) => <td key={index} style={{ border: '1px solid darkgray', padding: 4 }}>{value}</td>)}
              </tr>
            </tbody>
          </table>
        </section>

        <Section style={{ marginTop: 16 }}>
          <Autocomplete
            value={season}
            options={leagueData?.seasons.sort() ?? []}
            onChange={(event, value) => { setSeason(value) }}
            renderInput={(params) => (
              <RequiredTextField {...params} label='Season' error={!season} fullWidth />
            )}
            fullWidth
          />
          <Input
            inputRef={inputRef}
            type='file'
            placeholder='Schedule.csv'
            required
            error={error}
          />
          <Divider orientation='vertical' flexItem />
          <LoadingButton variant='contained' onClick={() => { void handleParseClick() }} disabled={!season}>Import CSV</LoadingButton>
        </Section>
        <Typography style={{ color: 'red' }}>{error && errorText}</Typography>
      </section>
      <LoadingDialog open={loading} body='Creating reports...' />
      <LoadingDialog open={dataLoading} body='Loading league data...' />
    </>
  )
}
