import React from 'react'

import { RequireAuth } from 'components/auth/RequireAuth'
import { type League } from 'utils/constants/constants'
import { MASLManageUser } from 'components/masl/manage-user/MASLManageUser'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/menu/MASLNavigationMenu'
import PageTitle from 'components/common/PageTitle'

const ManageUser: React.FC<{ league: League.MASL | League.M2 | League.M3 }> = ({ league }) => {
  return (
    <RequireAuth>
      <PageTitle title='Manage Users' />
      <DrawerNavigation menu={<MASLNavigationMenu league={league} />}>
        <MASLManageUser league={league} />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default ManageUser
