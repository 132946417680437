import { Add, Assessment, Description, People, UploadFile } from '@mui/icons-material'
import { Divider, List } from '@mui/material'
import { DrawerMenuItem } from 'components/common/menu/DrawerMenuItem'
import React, { useMemo } from 'react'
import { League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { Permission } from 'utils/providers/AuthProvider'

interface MASLNavigationMenuProps {
  league: League
}

const MASLNavigationMenu: React.FC<MASLNavigationMenuProps> = ({ league }) => {
  const { user } = useAuthState()
  const isAdmin = useMemo(() => league && user?.permissions[league].includes(Permission.ADMIN), [league, user?.permissions])

  return (
    <>
      <List>
        <DrawerMenuItem league={league} path='documents' icon={<Description />}>Documents</DrawerMenuItem>
      </List>
      {isAdmin && <Divider />}
      {isAdmin && (
        <List>
          <DrawerMenuItem league={league} path='create' icon={<Add />}>Create report</DrawerMenuItem>
          <DrawerMenuItem league={league} path='manage-user' icon={<People />}>Manage users</DrawerMenuItem>
          <DrawerMenuItem league={league} path='import' icon={<UploadFile />}>Import games</DrawerMenuItem>
          {league === League.MASL && (
            <>
              <DrawerMenuItem league={league} path='upload-player-list' icon={<UploadFile />}>Upload player list</DrawerMenuItem>
              <DrawerMenuItem league={league} path='export' icon={<Assessment />}>Penalty report</DrawerMenuItem>
            </>
          )}
        </List>
      )}
    </>
  )
}
export default MASLNavigationMenu
