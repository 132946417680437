import React from 'react'
import { isSafari, isDesktop } from 'react-device-detect'
import { BasicTextField } from '..'
import TimeInput from 'components/common/inputs/TimeInput'

interface DynamicTimeInputProps {
  value: string
  label: string
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  error?: boolean
}

const DynamicTimeInput: React.FC<DynamicTimeInputProps> = ({ value, label, placeholder, onChange, error }) => {
  return (
    <>
    {isSafari && isDesktop
      ? (
          <TimeInput
            value={value.toUpperCase()}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            error={error}
          />
        )
      : (
          <BasicTextField
            type='time'
            value={value}
            label={label}
            onChange={onChange}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            error={error}
          />
        )
      }
    </>
  )
}
export default DynamicTimeInput
