import React from 'react'
import { Route } from 'react-router-dom'

import { type MASLLeagueType } from 'utils/constants/masl'

import Create from 'pages/masl/create'
import Export from 'pages/masl/export'
import Import from 'pages/masl/import'
import ManageUser from 'pages/masl/manage-user'
import View from 'pages/masl/view'
import Report from 'pages/masl/report'
import UploadPlayerList from 'pages/masl/upload-player-list'
import Documents from 'pages/masl/documents'
import { League } from 'utils/constants/constants'
import Announcements from 'pages/masl/announcements'
import Home from 'pages/masl/home'

const leagues: MASLLeagueType[] = [League.MASL, League.M2, League.M3]

export const maslRoutes = [
  ...leagues.map((league) => <Route key={`${league}-home`} path={`${league}/home`} element={<Home league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-report`} path={`${league}/report/:id`} element={<Report league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-view`} path={`${league}/view/:id`} element={<View league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-create`} path={`${league}/create`} element={<Create league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-import`} path={`${league}/import`} element={<Import league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-manage-user`} path={`${league}/manage-user`} element={<ManageUser league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-documents`} path={`${league}/documents`} element={<Documents league={league} />} />),
  ...leagues.map((league) => <Route key={`${league}-announcements`} path={`${league}/announcements`} element={<Announcements league={league} />} />),
  <Route key='masl-export' path={`${League.MASL}/export`} element={<Export league={League.MASL} />} />,
  <Route key='masl-upload-player-list' path={`${League.MASL}/upload-player-list`} element={<UploadPlayerList />} />
]
