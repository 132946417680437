export const LAST_LEAGUE_SESSION_KEY = 'lastSelectedLeague'

export const USER_SESSION_STORAGE_KEY = 'userData'

export const FIREBASE_USER_SESSION_STORAGE_KEY = 'firebaseUserData'

export const SERVICE_ACCOUNT_ID = 'GXKWzsi264VFNuiHzOiYktFniia2'

export const USER_NAME_LOCAL_STORAGE_KEY = 'userName'

export interface LeagueDocument {
  name: string
  uri: string
}

export interface BaseSchema {
  id?: string
  league?: League
  date?: string
  homeTeam?: string
  awayTeam?: string
  author?: string
}

export enum League {
  MASL = 'masl',
  M2 = 'm2',
  M3 = 'm3',
  MASL_TEAMS = 'masl-teams'
}

export interface LeagueAnnouncement {
  id: string
  title: string
  body: string
  date: string | number
}
