import { encode } from 'base64-arraybuffer'

export function validate (value?: string | null): boolean {
  return value != null && value !== ''
}

export function validateEmail (email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export async function fileToBase64String (file: File | Blob): Promise<string> {
  return await new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader()

    // Register event listeners
    reader.addEventListener('loadend', e => {
      if (e.target?.result != null) {
        resolve(encode(e.target.result as ArrayBuffer))
      }
    })
    reader.addEventListener('error', reject)

    // Read file
    reader.readAsArrayBuffer(file)
  })
}

export function calculateTimeDifference (start: string, end: string) {
  const today = new Date()
  const startDate = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()} ${start}`)
  const endDate = new Date(`${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()} ${end}`)
  const diffMillis = (endDate.getTime() - startDate.getTime())
  const diffHrs = Math.floor((diffMillis % 86400000) / 3600000)
  const diffMins = Math.round(((diffMillis % 86400000) % 3600000) / 60000)
  return `${diffHrs} hours, ${diffMins} minutes`
}
