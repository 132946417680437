import React from 'react'
import { Checkbox, Divider, FormControlLabel, Typography } from '@mui/material'

import { NumericInput } from 'components/common'
import { type MASLTeam, type MASLReportSchema } from 'utils/constants/masl'

interface MASLScoresProps {
  homeTeam: MASLTeam | string
  awayTeam: MASLTeam | string
  homeScore: string
  awayScore: string
  shootoutOccurred?: boolean
  homeShootoutScore?: string
  awayShootoutScore?: string
  overtimeOccurred?: boolean
  onStateChange: (key: keyof MASLReportSchema, value: string | boolean | null) => void
}

const MASLScores: React.FC<MASLScoresProps> = ({ homeTeam, awayTeam, homeScore, awayScore, shootoutOccurred, homeShootoutScore, awayShootoutScore, overtimeOccurred, onStateChange }) => {
  return (
    <>
      <section style={{ display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8 }}>
        <section>
          <Typography>Did the match go to overtime?</Typography>
          <FormControlLabel control={<Checkbox checked={overtimeOccurred} onChange={(e) => { onStateChange('overtimeOccurred', e.target.checked) }} />} label="Overtime" />
        </section>
        <Divider />
        <Typography>{homeTeam} score</Typography>
        <NumericInput
          value={homeScore}
          label={`${homeTeam} score`}
          onChange={(event) => { onStateChange('homeScore', event.target.value) }}
          required
        />
      </section>
      <section style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 8, marginBottom: 8 }}>
        <Typography>{awayTeam} score</Typography>
        <NumericInput
          value={awayScore}
          label={`${awayTeam} score`}
          onChange={(event) => { onStateChange('awayScore', event.target.value) }}
          required
        />
      </section>
      <Divider />
      <section>
        <Typography>Did the match end in a shootout?</Typography>
        <FormControlLabel control={<Checkbox checked={shootoutOccurred} onChange={(e) => { onStateChange('shootoutOccurred', e.target.checked) }} />} label="Shootout" />
      </section>

      {shootoutOccurred && (
        <>
          <section style={{ display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8 }}>
            <Typography>{homeTeam} Shootout score</Typography>
            <NumericInput
              value={homeShootoutScore}
              label={`${homeTeam} Shootout score`}
              onChange={(event) => { onStateChange('homeShootoutScore', event.target.value) }}
              required
            />
          </section>
          <section style={{ display: 'flex', flexDirection: 'column', gap: 8, marginTop: 8, marginBottom: 8 }}>
            <Typography>{awayTeam} Shootout score</Typography>
            <NumericInput
              value={awayShootoutScore}
              label={`${awayTeam} Shootout score`}
              onChange={(event) => { onStateChange('awayShootoutScore', event.target.value) }}
              required
            />
          </section>
        </>
      )}

    </>
  )
}
export default MASLScores
