import {
  type UserCredential,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updateProfile,
  deleteUser
} from 'firebase/auth'

import { Permission, type User } from 'utils/providers/AuthProvider'
import { addUser, addUserWithCode, deleteUserData } from 'utils/api/api'
import { type League } from 'utils/constants/constants'
import { logClick } from 'utils/analytics'
import { clearLocal } from 'utils/localStorage'
import { clearSession } from 'utils/sessionStorage'

export async function logOut (): Promise<void> {
  const auth = getAuth()
  logClick('sign out')
  clearLocal()
  clearSession()
  await signOut(auth)
}

export async function logIn (email: string, password: string): Promise<UserCredential> {
  const auth = getAuth()
  return await signInWithEmailAndPassword(auth, email, password)
}

export async function sendResetEmail (email: string): Promise<void> {
  const auth = getAuth()
  await sendPasswordResetEmail(auth, email)
}

export async function signUpUser (email: string, password: string, displayName: string, leagueCode?: string) {
  const auth = getAuth()
  await logOut()
  const { error } = await addUserWithCode(email, password, leagueCode)
  if (error) throw new Error(error)
  const userCredential = await signInWithEmailAndPassword(auth, email, password)
  await updateProfile(userCredential.user, { displayName })
}

export async function createUser (userId: string, user: Partial<User>) {
  const auth = getAuth()
  const { email, newUser } = await addUser(userId, user)
  if (newUser) {
    await sendPasswordResetEmail(auth, email)
  }
}

export async function createUsers (userId: string, emails: string[], league: League, admin: boolean) {
  await Promise.all(emails.map(async email => {
    await createUser(userId, {
      email,
      leagues: [league],
      permissions: { [league]: (admin ? [Permission.ADMIN, Permission.EDITOR, Permission.VIEWER] : [Permission.EDITOR, Permission.VIEWER]) }
    })
  }))
}

export async function resetPassword (oobCode: string, newPassword: string) {
  const auth = getAuth()

  try {
    await verifyPasswordResetCode(auth, oobCode)
    await confirmPasswordReset(auth, oobCode, newPassword)
  } catch (e: any) {
    if (e.toString().includes('auth/invalid-action-code')) {
      throw Error('Invalid reset link')
    }
  }
}

export function validatePassword (password: string) {
  return password?.match(/(?=.*[A-Z])/) &&
    password?.match(/(?=.*[0-9])/) &&
    password?.match(/(?=.*[a-z].*[a-z])/) &&
    password.length >= 8
}

export async function deleteAccount () {
  const auth = getAuth()
  const user = auth.currentUser

  if (user) {
    await deleteUserData(user?.uid)
    await deleteUser(user)
  }
}
