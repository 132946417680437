import React from 'react'
import { Outlet } from 'react-router-dom'

import styles from './Root.module.scss'

const Root: React.FC = () => {
  return (
    <div className={styles.container}>
      <article>
        <Outlet />
      </article>
    </div>
  )
}

export default Root
