import React from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  CircularProgress,
  Typography
} from '@mui/material'

interface LoadingDialogProps {
  open: boolean
  body?: string
}

const LoadingDialog: React.FC<LoadingDialogProps> = ({ open, body }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth keepMounted={false}>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
        </IconButton>
      </Box>
      <DialogContent>
        <section style={{ textAlign: 'center' }}>
          <Typography variant='h5'>{body}</Typography>
        </section>
        <section
            style={{
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex',
              marginTop: '1em'
            }}
          >
            <CircularProgress />
          </section>
      </DialogContent>
    </Dialog>
  )
}

export default LoadingDialog
