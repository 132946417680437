import React, { useState } from 'react'
import { Divider, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { sendResetEmail } from 'utils/auth'
import { RequiredTextField, LinkButton } from 'components/common'
import TopNavigation from 'components/common/navigation/TopNavigation'

import styles from 'pages/auth/reset.module.scss'
import PageTitle from 'components/common/PageTitle'

const Reset: React.FC = () => {
  const [email, setEmail] = useState('')
  const [errorText, setErrorText] = useState<string>()
  const [successText, setSuccessText] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleReset (): void {
    setLoading(true)
    sendResetEmail(email.trim().toLowerCase()).then(() => {
      setSuccessText(true)
      setLoading(false)
    }).catch(e => {
      setErrorText(e?.toString() ?? 'Error')
      setLoading(false)
    })
  }

  return (
    <>
      <PageTitle title='Reset Password' />
      <TopNavigation>
        <section className={styles.container}>
          <Typography variant='h5'>Request password reset</Typography>
          <RequiredTextField
            label='E-mail'
            type='email'
            onChange={(event) => {
              setErrorText(undefined)
              setEmail(event.target.value)
            }}
          />
          <LoadingButton variant="contained" onClick={handleReset} loading={loading}>Reset</LoadingButton>
          {errorText && <p style={{ color: 'red' }}>Error sending reset e-mail.<br />{errorText}</p>}
          {successText && <p style={{ color: 'green' }}>Successfully sent reset e-mail.</p>}
          <Divider />
          <LinkButton to='/auth/sign-in'>Go to log in</LinkButton>
        </section>
      </TopNavigation>
    </>
  )
}
export default Reset
