import React from 'react'

import { RequireAuth } from 'components/auth/RequireAuth'
import { MASLExport } from 'components/masl/export/MASLExport'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/menu/MASLNavigationMenu'
import { type League } from 'utils/constants/constants'
import PageTitle from 'components/common/PageTitle'

const Export: React.FC<{ league: League.MASL }> = ({ league }) => {
  return (
    <RequireAuth>
      <PageTitle title='Export Penalties' />
      <DrawerNavigation menu={<MASLNavigationMenu league={league} />}>
        <MASLExport />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default Export
