import React, { useMemo } from 'react'

interface LocaleTimeProps {
  date?: string
  time?: string
}

const LocaleTime: React.FC<LocaleTimeProps> = ({ date, time }) => {
  const today = useMemo(() => new Date().toLocaleDateString(), [])

  return (
    <>{new Date(`${date ?? today} ${time ?? '12:00 PM'}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>
  )
}
export default LocaleTime
