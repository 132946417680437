import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Backdrop,
  CircularProgress,
  Divider,
  Link,
  Box,
  Typography,
  Button
} from '@mui/material'
import { useParams } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import html2PDF from 'jspdf-html2canvas'

import { Title, Section, Text, LinkButton } from 'components/common/index'
import { MASL_QUESTIONS, type MASLReportSchema, type Penalty, type Review, Period, KMI_QUESTION, DEFAULT_MASL_REPORT } from 'utils/constants/masl'
import { League } from 'utils/constants/constants'
import { useReport } from 'utils/hooks/useReport'
import { useAuthState } from 'utils/hooks/useAuthState'
import { Permission } from 'utils/providers/AuthProvider'
import PageTitle from 'components/common/PageTitle'
import OpenImageButton from 'components/common/OpenImageButton'

import './MASLView.module.scss'
import { calculateTimeDifference } from 'utils'
import LocaleTime from 'components/common/LocaleTime'

function convertPeriodToNumber (period: Period | undefined): number {
  switch (period) {
    case Period.ONE:
      return 1
    case Period.TWO:
      return 2
    case Period.THREE:
      return 3
    case Period.FOUR:
      return 4
    case Period.OT:
      return 5
    case Period.SHOOTOUT:
      return 6
    default:
      return 0
  }
}

export const MASLView: React.FC<{ league: League }> = ({ league }) => {
  const { user } = useAuthState()
  const { id = '' } = useParams()
  const { report, loading, error } = useReport<MASLReportSchema>(league, id, DEFAULT_MASL_REPORT)

  const gameLength = useMemo(() => report?.firstHalfStart && report?.gameEndTime ? calculateTimeDifference(report.firstHalfStart, report.gameEndTime) : '', [report?.firstHalfStart, report?.gameEndTime])

  async function exportToPdf () {
    const node = document.getElementById('report-body')
    if (!node) {
      console.error('Error exporting PDF')
      return
    }
    return await html2PDF(node, {
      jsPDF: {
        format: 'a4'
      },
      imageType: 'image/jpeg',
      output: `report-${report?.gameNumber ?? ''}.pdf`,
      margin: { right: 16, top: 16, bottom: 16, left: 16 },
      success: (pdf) => {
        pdf.save(`report-${report?.gameNumber ?? ''}.pdf`)
      }
    })
  }

  if (loading) {
    return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" size={70} />
    </Backdrop>
    )
  }

  if (error) {
    return <h1 style={{ textAlign: 'center' }}>Report does not exist.<br /><Link href={`/${league}/home`}>Home</Link></h1>
  }

  return (
    <section>
      <PageTitle title={`${league.toLocaleUpperCase()} - View: ${report?.gameNumber ?? ''}`} />
      {!report?.editable && <Button style={{ marginBottom: '1em' }} variant='contained' onClick={() => { void exportToPdf() }}>Export to PDF</Button>}
      <Divider />
      <article id='report-body'>
        <Typography margin={'0.5em 0'} variant='h5'>Official {league.toUpperCase()} Match Report</Typography>
        <Divider />
        <Title>Game Information</Title>
        <Section>
          <Text
            value={dayjs(report?.date).toDate().toLocaleDateString()}
            label='Game date'
          />
          <Text
            value={<LocaleTime date={report?.date} time={report?.gameTime} />}
            label='Game time'
          />
          <Text value={report?.location} label='Location' />
          <Text value={report?.overtimeOccurred ? 'Yes' : 'No'} label='Overtime' />
          <Text value={report?.shootoutOccurred ? 'Yes' : 'No'} label='Shootout' />
        </Section>

        <Divider />

        <section style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginBottom: '1em' }}>
          <Title>{report?.homeTeam}</Title>
          <Text value={report?.homeTeam} label='Home' />
          <Text value={`${report?.homeScore ?? ''} ${report?.shootoutOccurred ? `(${report?.homeShootoutScore ?? ''})` : ''}`} label={`${report?.homeTeam ?? 'Home'} Score`} />
          <Text label={`${report?.homeTeam ?? ''} roster`} value={
            report?.homeRosterImageUrl
              ? (
                <section style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
                  <OpenImageButton filePath={report?.homeRosterImageUrl ?? ''}>Open {report?.homeTeam} Roster Image</OpenImageButton>
                </section>
                )
              : <>None uploaded</>
            }
          />
        </section>
        <Divider />
        <section style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginBottom: '1em' }}>
          <Title>{report?.awayTeam}</Title>
          <Text value={report?.awayTeam} label='Away' />
          <Text value={`${report?.awayScore ?? ''} ${report?.shootoutOccurred ? `(${report?.awayShootoutScore ?? ''})` : ''}`} label={`${report?.awayTeam ?? 'Away'} Score`} />
          <Text label={`${report?.awayTeam ?? ''} roster`} value={
            report?.awayRosterImageUrl
              ? (
                <section style={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
                  <OpenImageButton filePath={report?.awayRosterImageUrl}>Open {report?.awayTeam} Roster Image</OpenImageButton>
                </section>
                )
              : <>None uploaded</>
            }
          />
        </section>

        <Divider />

        <Title>Officials</Title>
        <Section>
          <Text value={report?.seniorReferee} label='Senior Referee' />
          <Text value={report?.referee} label='Referee' />
          {report?.thirdReferee && <Text value={report?.thirdReferee} label='Third Referee' />}
          <Text value={report?.assistantReferee} label='Assistant Referee' />
          <Text value={report?.fourthOfficial} label='4th Official' />
          <Text value={report?.otherOfficials} label='Other Official(s)' />
        </Section>

        <Divider />

        <Title>Game Time</Title>
        <Section>
          <Text value={<LocaleTime date={report?.date} time={report?.firstHalfStart} />} label='Game start time' />
          <Text value={report?.firstHalfStartExplanation} label='If starting late, why?' />
        </Section>
        <Section>
          <Text value={<LocaleTime date={report?.date} time={report?.secondHalfStart} />} label='2nd half start time' />
          <Text value={report?.secondHalfStartExplanation} label='If starting late, why?' />
        </Section>
        <Section>
          <Text value={<LocaleTime date={report?.date} time={report?.gameEndTime} />} label='Game end time' />
        </Section>
        <Section>
          <Text value={gameLength} label='Game length' />
        </Section>

        <Divider />

        <Title>Penalties &amp; Ejections</Title>
        {(report?.penalties?.length ?? 0) > 0
          ? (
              <Section>
                <TableContainer component={Paper}>
                  <Table sx={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Player/Coach</TableCell>
                        <TableCell align="left">Player name</TableCell>
                        <TableCell align="right">Player Number</TableCell>
                        <TableCell align="left">Team</TableCell>
                        <TableCell align="right">Period</TableCell>
                        <TableCell align="right">Clock Time</TableCell>
                        <TableCell align="left">Explanation</TableCell>
                        <TableCell align="right">Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(report?.penalties ?? []).sort((a, b) => {
                        const periodComp = convertPeriodToNumber(a?.period ?? Period.ONE) - convertPeriodToNumber(b?.period ?? Period.ONE)
                        if (periodComp === 0) {
                          return (b?.time ?? '').localeCompare(a?.time ?? '')
                        } else {
                          return periodComp
                        }
                      }).map((penalty: Penalty) => (
                        <TableRow
                          key={penalty.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{penalty.playerType}</TableCell>
                          <TableCell align="left">{penalty.name}</TableCell>
                          <TableCell align="right">{penalty.number}</TableCell>
                          <TableCell align="left">{penalty.team}</TableCell>
                          <TableCell align="right">{penalty.period}</TableCell>
                          <TableCell align="right">{penalty.time}</TableCell>
                          <TableCell align="left">{penalty.explanation}</TableCell>
                          <TableCell align="right">{penalty.code}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Section>
            )
          : (<Section>None</Section>)
        }

        <Divider />

        <Title>Video Review</Title>
        {(report?.reviews?.length ?? 0) > 0
          ? (
              <Section>
                <TableContainer component={Paper}>
                  <Table sx={{ width: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>Initiator</TableCell>
                        <TableCell align="left">Team</TableCell>
                        <TableCell align="right">Period</TableCell>
                        <TableCell align="right">Time</TableCell>
                        <TableCell align="right">Category</TableCell>
                        <TableCell align="right">Code</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(report?.reviews ?? []).sort((a, b) => {
                        const periodComp = convertPeriodToNumber(a?.period ?? Period.ONE) - convertPeriodToNumber(b?.period ?? Period.ONE)
                        if (periodComp === 0) {
                          return (b?.time ?? '').localeCompare(a?.time ?? '')
                        } else {
                          return periodComp
                        }
                      }).map((review: Review) => (
                        <TableRow
                          key={review.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{review.initiator}</TableCell>
                          <TableCell align="left">{review.team}</TableCell>
                          <TableCell align="right">{review.period}</TableCell>
                          <TableCell align="right">{review.time}</TableCell>
                          <TableCell align="right">{review.category}</TableCell>
                          <TableCell align="right">{review.code}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Section>
            )
          : (<Section>None</Section>)}

        <Divider />
        <Title>Worksheet</Title>
        <Section>
          {report?.worksheetImageUrl
            ? (
                <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
                  <CheckCircleIcon color='success' />
                  <OpenImageButton filePath={report?.worksheetImageUrl ?? ''}>Worksheet Image</OpenImageButton>
                </section>
              )
            : (<>None</>)
          }
        </Section>

        <Divider />
        <Title>Notes</Title>
        <Box display={'flex'} flexDirection={'column'} gap='1em' flexWrap={'wrap'}>
          <section>
            <Typography><b>Match Notes or Supplementals:</b></Typography>
            <pre>{report?.notes && report?.notes !== '' ? report?.notes : 'None.'}</pre>
          </section>
          <Text value={<pre>{report?.leagueStandardsQuestions?.[KMI_QUESTION]?.answer ?? 'No'}. {report?.leagueStandardsQuestions?.[KMI_QUESTION]?.note ?? ''}</pre>} label={KMI_QUESTION} />
          {league === League.MASL && MASL_QUESTIONS.map((question) => (
            <Text key={question} value={<pre>{report?.leagueStandardsQuestions?.[question]?.answer ?? 'Yes'}. {report?.leagueStandardsQuestions?.[question]?.note ?? ''}</pre>} label={question} />
          ))}
        </Box>
      </article>
      {report?.editable && user?.permissions[league].includes(Permission.EDITOR) && (
        <section>
          <LinkButton
            style={{ marginTop: 8 }}
            variant='contained'
            to={`/${league}/report/${id}`}
            fullWidth
            options={{ replace: true }}
          >
            Edit report
          </LinkButton>
        </section>
      )}
    </section>

  )
}
