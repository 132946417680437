
import React, { useState } from 'react'
import { Autocomplete, Button, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormHelperText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSearchParams } from 'react-router-dom'

import { Title } from 'components/common'
import { validateEmail } from 'utils'
import { createUsers } from 'utils/auth'
import { useAuthState } from 'utils/hooks/useAuthState'
import { type League } from 'utils/constants/constants'
import { type User } from 'utils/providers/AuthProvider'
import { useLeagueUsers } from 'utils/hooks/useLeagueUsers'
import { removeUser } from 'utils/api/api'
import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import { SERVICE_ACCOUNT_ID } from 'utils/constants/constants'

interface ManageUserProps {
  league: League
}

export const MASLManageUser: React.FC<ManageUserProps> = ({ league }) => {
  const { user } = useAuthState()
  const [searchParams, setSearchParams] = useSearchParams({ success: 'false' })
  const [emails, setEmails] = useState<string[]>([])
  const [admin, setAdmin] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [userToDelete, setUserToDelete] = useState<string>()
  const [userToDeleteEmail, setUserToDeleteEmail] = useState<string>()
  const successParam = searchParams.get('success')
  const { data, loading: dataLoading, trigger } = useLeagueUsers(league)

  async function handleAddUsers () {
    setLoading(true)
    if (user?.id) {
      createUsers(user.id, emails, league, admin).then(() => {
        setLoading(false)
        setSearchParams({ success: 'true', league })
        setEmails([])
        trigger()
      }).catch((e) => {
        console.error(e)
        setLoading(false)
      })
    }
  }

  function handleEmailChange (event: React.SyntheticEvent<Element, Event>, value: string[]) {
    const addedEmails: string[] = []

    if (value.length === 0) {
      setEmailError(true)
      return
    }

    for (const email of value) {
      const split = email.split(',').map(email => email.trim().toLowerCase())

      if (!split.every(e => validateEmail(e))) {
        setEmailError(true)
        return
      }

      addedEmails.push(...split)
    }

    setEmails(addedEmails)
    setEmailError(false)
  }

  function handleRemoveUserClick (userId: string, userEmail: string) {
    setShowConfirmDialog(true)
    setUserToDelete(userId)
    setUserToDeleteEmail(userEmail)
  }

  function handleRemoveUser () {
    setLoading(true)
    if (user?.id && userToDelete) {
      removeUser(user?.id, userToDelete, league).then((r) => {
        trigger()
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        setLoading(false)
        setShowConfirmDialog(false)
      })
    }
  }

  return (
    <>
      {successParam === 'true' && <Title style={{ color: 'green' }}>Successfully added user(s) to league</Title>}
      <Title>Add users to {league.toUpperCase()}</Title>
      <section style={{ marginBottom: '1em' }}>
        <FormControl error={emailError || emails.length === 0} fullWidth>
          <Autocomplete
            multiple
            id="tags-filled"
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const isValid = validateEmail(option)
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Chip
                    variant="outlined"
                    label={isValid ? option : `${option} (Invalid)`}
                    {...getTagProps({ index })}
                    color={isValid ? undefined : 'error'}
                  />
                )
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="E-mails"
                placeholder="Press Enter &#8629; to add e-mail" />
            )}
            onChange={handleEmailChange}
            options={[]}
            value={emails}
          />
          <FormHelperText>You must add at least one e-mail (Press Enter &#8629; to add e-mail).</FormHelperText>
          <FormHelperText>All emails must be valid.</FormHelperText>
        </FormControl>
      </section>
      <section>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={admin}
                onChange={(e, checked) => {
                  setAdmin(checked)
                }}
                name={'administrator'}
              />
            }
            label={'Make users league administrators'}
          />
        </FormControl>
      </section>
      <LoadingButton
        style={{ marginTop: 16, marginBottom: 16 }}
        variant='contained'
        color='success'
        disabled={emailError || emails.length === 0}
        onClick={() => { void handleAddUsers() }}
        loading={loading}
      >
          Add users
      </LoadingButton>

      <Divider />
      {dataLoading && (
        <section style={{
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          display: 'flex',
          marginTop: '1em'
        }}>
          <CircularProgress />
        </section>
      )}
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align='left'>E-mail</TableCell>
              <TableCell align='left'>Permissions</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.users.filter(user => user.id !== SERVICE_ACCOUNT_ID).map((u: User) => (
                <TableRow
                  key={u.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='left'>{u.email}</TableCell>
                  <TableCell align="left">{u.permissions[league].map((p) => <span key={p} style={{ marginRight: 8 }}>{p.toLowerCase()}</span>)}</TableCell>
                  <TableCell align="left">
                    <Button color='error' onClick={() => { handleRemoveUserClick(u.id, u.email) }}>Remove User</Button>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        open={showConfirmDialog}
        title='Confirm user removal'
        body={`Please confirm you want to remove user ${userToDeleteEmail ?? ''} from ${league.toUpperCase()}`}
        onOk={handleRemoveUser}
        loading={loading}
        onCancel={() => {
          setUserToDelete(undefined)
          setShowConfirmDialog(false)
        }}
      />
    </>
  )
}
