import React, { useEffect } from 'react'
import { usePageTitle } from 'utils/hooks/usePageTitle'

interface PageTitleProps {
  title: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  const [, setTitle] = usePageTitle()

  useEffect(() => {
    setTitle(title)
  }, [setTitle, title])

  return (
    <></>
  )
}
export default PageTitle
