export function setSession (key: string, value: any): void {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export function getSession<T> (key: string): T | undefined {
  try {
    const stored = sessionStorage.getItem(key)
    if (stored === 'undefined' || !stored) {
      return undefined
    }
    return JSON.parse(stored) as T
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export function removeSession (key: string): void {
  sessionStorage.removeItem(key)
}

export function clearSession () {
  sessionStorage.clear()
}
