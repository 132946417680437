import { Typography, Box, Button, CircularProgress, Divider } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { deleteLeagueAnnouncement, addLeagueAnnouncement } from 'utils/api/api'
import { type League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { Permission } from 'utils/providers/AuthProvider'
import { RequiredTextField } from 'components/common'
import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import { useLeagueAnnouncements } from 'utils/hooks/useLeagueAnnouncements'

import './LeagueAnnouncements.module.scss'

interface LeagueAnnouncementsProps {
  league: League
}

const LeagueAnnouncements: React.FC<LeagueAnnouncementsProps> = ({ league }) => {
  const { user } = useAuthState()
  const { data, loading, trigger } = useLeagueAnnouncements(league)
  const isAdmin = useMemo(() => user?.permissions[league].includes(Permission.ADMIN), [league, user?.permissions])
  const [announcementId, setAnnouncementId] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)

  function showDeleteConfirm (id: string) {
    setAnnouncementId(id)
    setShowDeleteDialog(true)
  }

  function deleteAnnouncement () {
    setDialogLoading(true)
    deleteLeagueAnnouncement(league, announcementId).then(() => {
      trigger()
      setShowDeleteDialog(false)
      setDialogLoading(false)
    }).catch((e) => {
      console.error(e)
    })
  }

  function addAnnouncement () {
    setDialogLoading(true)
    addLeagueAnnouncement(league, { id: announcementId !== '' ? announcementId : uuid(), title, body, date: Date.now() }).then(() => {
      setShowAddDialog(false)
      setDialogLoading(false)
    }).catch((e) => {
      console.error(e)
    }).finally(() => {
      setAnnouncementId('')
      setTitle('')
      setBody('')
      trigger()
    })
  }

  return (
    <>
      {data?.announcements?.map((doc) => (
        <Box margin='0.5em' alignItems='center' gap='0.5em' key={doc.id} flexWrap='wrap' borderRadius={'8px'} border={'solid 1px'} padding={'1em'} flexDirection={'column'}>
          <Typography variant='caption'>{new Date(doc.date).toLocaleDateString()}</Typography>
          <Box display='flex' flexDirection={'row'} alignItems={'center'} gap='1em'>
            <Typography variant='h5'>{doc.title}</Typography>
            {isAdmin && (
              <section style={{ marginLeft: 'auto' }}>
                <Button
                  onClick={() => {
                    setTitle(doc.title)
                    setBody(doc.body)
                    setAnnouncementId(doc.id)
                    setShowAddDialog(true)
                  }}
                >Edit</Button>
                <Button onClick={() => { showDeleteConfirm(doc.id) }} color='warning'>Delete</Button>
              </section>
            )}
          </Box>
          <Divider />
          <pre>{doc.body}</pre>
        </Box>
      ))}
      {!loading && data?.announcements?.length === 0 && <Typography variant='h6' marginTop='1em'>No announcements</Typography>}
      {loading && <Box marginTop='1em'><CircularProgress /></Box>}
      {isAdmin && (
        <Box margin={'auto'} textAlign={'center'} marginTop={'1em'}>
          <Button onClick={() => {
            setTitle('')
            setBody('')
            setAnnouncementId('')
            setShowAddDialog(true)
          }}>Add announcement</Button>
        </Box>
      )}
      <ConfirmDialog
        open={showDeleteDialog}
        title={'Delete document'}
        body={(
          <>Confirm you want to delete this announcement.</>
        )}
        onOk={() => {
          deleteAnnouncement()
        }}
        onCancel={() => {
          setAnnouncementId('')
          setShowDeleteDialog(false)
        }}
        confirmColor='error'
        loading={dialogLoading}
      />
      <ConfirmDialog
        open={showAddDialog}
        title={`${announcementId ? 'Edit' : 'Add'} a document`}
        body={(
          <Box display='flex' gap='1em' flexDirection='column'>
            <RequiredTextField value={title} label='Title' placeholder='Title' onChange={(event) => { setTitle(event.target.value) }} fullWidth />
            <RequiredTextField value={body} label='Body' placeholder='Body' onChange={(event) => { setBody(event.target.value) }} fullWidth multiline rows={10} />
          </Box>
        )}
        onOk={() => {
          addAnnouncement()
        }}
        onCancel={() => {
          setShowAddDialog(false)
          setTitle('')
          setBody('')
          setAnnouncementId('')
        }}
        confirmText={announcementId ? 'Update' : 'Add'}
        loading={dialogLoading}
      />
    </>
  )
}
export default LeagueAnnouncements
