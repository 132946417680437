import { Typography } from '@mui/material'
import React from 'react'

interface PasswordRequirementsProps {
  password: string | undefined
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ password }) => {
  return (
    <section style={{ textAlign: 'left' }}>
      <Typography>Your password must meet the following requirements:</Typography>
      <ul>
        <li style={{ color: password?.match(/(?=.*[A-Z])/) ? 'green' : 'red' }}>One uppercase letter</li>
        <li style={{ color: password?.match(/(?=.*[0-9])/) ? 'green' : 'red' }}>One number</li>
        <li style={{ color: password?.match(/(?=.*[a-z].*[a-z])/) ? 'green' : 'red' }}>Two lowercase letters</li>
        <li style={{ color: password && password.length >= 8 ? 'green' : 'red' }}>At least 8 characters long</li>
      </ul>
    </section>
  )
}
export default PasswordRequirements
