import { type User as FirebaseUser } from '@firebase/auth'
import { createContext } from 'react'
import { type User } from 'utils/providers/AuthProvider'

export interface AuthContextResult {
  firebaseUser: FirebaseUser | null
  user?: User
  loading: boolean
}

export const AuthContext = createContext<AuthContextResult>({ firebaseUser: null, user: undefined, loading: true })
