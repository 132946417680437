import React from 'react'
import ImageUploader, { type FileObjectType } from 'react-image-upload'
import 'react-image-upload/dist/index.css'

import { type League } from 'utils/constants/constants'
import { uploadReportImage } from 'utils/api/api'

interface UploadImageProps {
  fileName?: string
  league: League
  reportId: string
  onUpload: (url: string) => void
  onUploadStart?: () => void
  onUploadFinish?: () => void
  onError?: (error: any) => void
}

const UploadImage: React.FC<UploadImageProps> = ({ fileName, league, reportId, onUpload, onUploadStart, onUploadFinish, onError }) => {
  function handleFileAdded (img: FileObjectType) {
    const fileExtension = img.file.name.split('.').pop() ?? '.jpg'
    onUploadStart?.()
    uploadReportImage(
      fileName
        ? `${fileName}.${fileExtension}`
        : img.file.name,
      img.file,
      league,
      reportId
    ).then((fileName) => {
      onUpload(fileName)
      onUploadFinish?.()
    }).catch(function (error) {
      onError?.(error)
      console.error(error)
    })
  }

  return (
    <ImageUploader onFileAdded={(img) => { handleFileAdded(img) }} />
  )
}
export default UploadImage
