import React from 'react'
import MaskedInput from 'react-text-mask'

import { BasicTextField } from 'components/common'

interface TimeInputProps {
  style?: React.CSSProperties
  className?: string
  value?: string | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  error?: boolean
  label?: string
  placeholder?: string
}

const TimeInput: React.FC<TimeInputProps> = ({ style, className, value, onChange, error, label, placeholder }) => {
  return (
    <MaskedInput
      style={style}
      mask={[/[0-1]/, /[0-9]/, ':', /[0-9]/, /[0-9]/, ' ', /([AaPp])/, 'M']}
      type="tel"
      className={className}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      guide
      render={(ref, props) => (
        <BasicTextField
          inputRef={ref}
          required
          error={error}
          label={label}
          InputLabelProps={{ shrink: true }}
          {...props}
        />
      )}
    />
  )
}
export default TimeInput
