import React, { type ReactNode } from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Button, type ButtonProps, TextField, Link, type TextFieldProps } from '@mui/material'
import styled from 'styled-components'
import { type NavigateOptions, useNavigate } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const CSVSupportLink: React.FC = () => <Link href='https://en.wikipedia.org/wiki/Comma-separated_values' target='_blank' rel='noopener noreferrer'>CSV</Link>

export const BasicTextField = (props: TextFieldProps) => {
  return (
    <TextField
      variant='outlined'
      autoComplete='off'
      autoCorrect='off'
      {...props}
      inputProps={{ spellCheck: false, ...props.inputProps }}
    />
  )
}

export const DisabledTextField = styled(BasicTextField).attrs({
  disabled: true
})``

export const RequiredTextField = (props: TextFieldProps) => {
  return <BasicTextField {...props} required />
}

interface NumericInputProps {
  label?: string
  placeholder?: string
  value?: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  required?: boolean
  allowDecimal?: boolean
}

export const NumericInput = (props: NumericInputProps) => {
  const numericMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: props.allowDecimal,
    allowNegative: false
  })

  return (
    <MaskedInput
      type="tel"
      placeholder={props.placeholder}
      mask={numericMask}
      value={props.value ?? ''}
      onChange={props.onChange}
      render={(ref, p) => (
        <BasicTextField inputRef={ref} type='tel' label={props.label} {...p} required />
      )}
    />
  )
}

export const Section = styled.section`
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  margin-bottom: 1em;
`

export const BasicTimePicker: typeof TimePicker = styled(TimePicker)`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const BasicDatePicker = styled(DatePicker)`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const BasicButton = styled(Button).attrs({
  variant: 'contained'
})`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const SecondaryButton = styled(BasicButton).attrs({
  color: 'secondary'
})``

export const SubmitButton = styled(BasicButton).attrs({
  color: 'success'
})`
`

export const Title = styled.h3`

`

export const ErrorTitle = styled(Title)`
  color: red;
`

const TextContainer = styled.section`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const Text: React.FC<{ label?: string, value?: ReactNode }> = ({ label, value }) => (
  <TextContainer>
    <b>{label}:</b>
    <br />
    <span>{value}</span>
  </TextContainer>
)

export const LinkButton: React.FC<{ to: string, children?: ReactNode, options?: NavigateOptions, onBeforeNavigate?: () => void } & ButtonProps> = ({ to, children, options, onBeforeNavigate, ...props }) => {
  const navigate = useNavigate()

  return (
    <Button
      onClick={() => {
        onBeforeNavigate?.()
        navigate(to, options)
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export const ExternalLinkButton: React.FC<{ to: string, children?: ReactNode, target: string, rel: string } & ButtonProps> = ({ to, children, target, rel, ...props }) => {
  const a = document.createElement('a')
  a.setAttribute('href', to)
  a.setAttribute('target', target)
  a.setAttribute('rel', rel)
  return <Button onClick={() => { a.click() }} {...props}>{children}</Button>
}
