import React from 'react'

import { RequiredTextField, BasicTextField } from 'components/common'
import { type MASLReportSchema } from 'utils/constants/masl'

interface MASLOfficialsProps {
  seniorReferee: string
  referee: string
  thirdReferee?: string
  assistantReferee: string
  fourthOfficial: string
  otherOfficials: string
  onStateChange: (key: keyof MASLReportSchema, value: string | null) => void
}

const MASLOfficials: React.FC<MASLOfficialsProps> = ({ onStateChange, ...props }) => {
  return (
    <section style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <RequiredTextField value={props.seniorReferee} label='Senior Referee' onChange={(event) => { onStateChange('seniorReferee', event.target.value) }} />
      <RequiredTextField value={props.referee} label='Referee' onChange={(event) => { onStateChange('referee', event.target.value) }} />
      <BasicTextField value={props.thirdReferee} label='Third Referee' onChange={(event) => { onStateChange('thirdReferee', event.target.value) }} />
      <RequiredTextField value={props.assistantReferee} label='Assistant Referee' onChange={(event) => { onStateChange('assistantReferee', event.target.value) }} />
      <RequiredTextField value={props.fourthOfficial} label='4th Official' onChange={(event) => { onStateChange('fourthOfficial', event.target.value) }} />
      <RequiredTextField value={props.otherOfficials} label='Other Official(s)' onChange={(event) => { onStateChange('otherOfficials', event.target.value) }} />
    </section>
  )
}
export default MASLOfficials
