import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Divider, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Cancel } from '@mui/icons-material'

import { Section } from 'components/common'
import UploadImage from 'components/common/UploadImage'
import { type League } from 'utils/constants/constants'
import { type MASLReportSchema } from 'utils/constants/masl'
import OpenImageButton from 'components/common/OpenImageButton'

interface MASLRostersProps {
  homeTeam: string
  homeRosterImageUrl: string | undefined
  awayTeam: string
  awayRosterImageUrl: string | undefined
  league: League
  id: string
  onStateChange: (key: keyof MASLReportSchema, value: string | null) => void
  onLoadingChange?: (loading: boolean) => void
}

const MASLRosters: React.FC<MASLRostersProps> = ({ league, id, homeTeam, homeRosterImageUrl, awayTeam, awayRosterImageUrl, onStateChange, onLoadingChange }) => {
  const [uploadingHome, setUploadingHome] = useState(false)
  const [uploadingAway, setUploadingAway] = useState(false)
  const [uploadingHomeError, setUploadingHomeError] = useState(false)
  const [uploadingAwayError, setUploadingAwayError] = useState(false)

  useEffect(() => {
    onLoadingChange?.(uploadingAway || uploadingHome)
  }, [onLoadingChange, uploadingAway, uploadingHome])

  return (
    <>
      <Typography>{homeTeam} roster</Typography>
      <Section style={{ marginTop: '1em' }}>
        {!homeRosterImageUrl && !uploadingHome && <UploadImage
          fileName={`home-${homeTeam.toLowerCase().replace(' ', '-')}-roster`}
          league={league}
          reportId={id}
          onUpload={(url) => {
            setUploadingHome(false)
            onStateChange('homeRosterImageUrl', url)
          }}
          onUploadStart={() => {
            setUploadingHomeError(false)
            setUploadingHome(true)
          }}
          onError={() => { setUploadingHomeError(true) }}
        />}
        {homeRosterImageUrl && !uploadingHome && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <CheckCircleIcon color='success' />
              <OpenImageButton filePath={homeRosterImageUrl}>{homeTeam} Roster Image</OpenImageButton>
            </section>
            <Button color='warning' onClick={() => { onStateChange('homeRosterImageUrl', null) }}>Delete and upload new image</Button>
          </>
        )}
        {uploadingHome && (
          <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
            <CircularProgress size={24} />
            <>Uploading Image...</>
          </section>
        )}
        {!homeRosterImageUrl && uploadingHomeError && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <Cancel color='error' />
              <>Error Uploading Image</>
            </section>
          </>
        )}
      </Section>
      <Divider />
      <Typography>{awayTeam} roster</Typography>
      <Section style={{ marginTop: '1em' }}>
        {!awayRosterImageUrl && !uploadingAway && <UploadImage
          fileName={`away-${awayTeam.toLowerCase().replace(' ', '-')}-roster`}
          league={league}
          reportId={id}
          onUpload={(url) => {
            setUploadingAway(false)
            onStateChange('awayRosterImageUrl', url)
          }}
          onUploadStart={() => {
            setUploadingAwayError(false)
            setUploadingAway(true)
          }}
          onError={() => { setUploadingAwayError(true) }}
        />}
        {awayRosterImageUrl && !uploadingAway && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <CheckCircleIcon color='success' />
              <OpenImageButton filePath={awayRosterImageUrl}>{awayTeam} Roster Image</OpenImageButton>
            </section>
            <Button color='warning' onClick={() => { onStateChange('awayRosterImageUrl', null) }}>Delete and upload new image</Button>
          </>
        )}
        {uploadingAway && (
          <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
            <CircularProgress size={24} />
            <>Uploading Image...</>
          </section>
        )}
        {!awayRosterImageUrl && uploadingAwayError && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <Cancel color='error' />
              <>Error Uploading Image</>
            </section>
          </>
        )}
      </Section>
    </>
  )
}
export default MASLRosters
