import React, { useState } from 'react'
import { Button, Divider, Typography } from '@mui/material'

import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import { deleteAccount } from 'utils/auth'

const ManageAccount: React.FC = () => {
  const [deleteAccountConfirmOpen, setDeleteAccountConfirmOpen] = useState(false)

  function handleDeleteClick () {
    setDeleteAccountConfirmOpen(true)
  }

  function handleDeleteConfirmClick () {
    void deleteAccount()
  }

  return (
    <>
      <Divider />
      <Typography variant='h6'>Account deletion</Typography>
      <section style={{ marginBottom: '10px' }}>
        <Button
          onClick={handleDeleteClick}
          color='error'
          variant='contained'
        >
          Delete my Account
        </Button>
      </section>
      <Divider />
      <ConfirmDialog
        open={deleteAccountConfirmOpen}
        title={'Delete account'}
        body={'Are you sure you want to delete your account? You will lose access to all the leagues you are a part of. This action cannot be undone.'}
        onOk={handleDeleteConfirmClick}
        onCancel={function (): void {
          setDeleteAccountConfirmOpen(false)
        } }
        confirmColor='error'
        confirmText='Delete account'
      />
    </>
  )
}

export default ManageAccount
