import { LoadingButton } from '@mui/lab'
import { Link } from '@mui/material'
import React, { type ReactNode, useState, useEffect } from
  'react'
import { getImageUrl } from 'utils/storage'

interface OpenImageButtonProps {
  filePath: string
  children?: ReactNode
}

const OpenImageButton: React.FC<OpenImageButtonProps> = ({ filePath, children }) => {
  const [fullImageUrl, setFullImageUrl] = useState<string>()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getImageUrl(filePath).then((url) => {
      setFullImageUrl(url)
    }).catch(() => {
      setError(true)
    }).finally(() => {
      setLoading(false)
    })
  }, [filePath])

  return (
    <>
      {loading && <LoadingButton variant='text' loading={true}>{children}</LoadingButton>}
      {!error ? <Link href={fullImageUrl} target='_blank' rel='noopener noreferrer'>{children}</Link> : 'Image is not valid.'}
    </>
  )
}
export default OpenImageButton
