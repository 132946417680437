import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import styles from 'components/common/LoadingSpinner.module.scss'

const LoadingSpinner: React.FC = () => {
  return (
    <section className={styles.container}>
      <CircularProgress size={80} />
    </section>
  )
}
export default LoadingSpinner
