import { useCallback } from 'react'
import { type NavigateOptions, useNavigate } from 'react-router-dom'

import { logOut } from 'utils/auth'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useActiveLeague } from 'utils/hooks/useActiveLeague'
import { type League } from 'utils/constants/constants'

export const useRedirectToHome = (league?: League) => {
  const navigate = useNavigate()
  const { user } = useAuthState()
  const [activeLeague, setActiveLeague] = useActiveLeague()

  const redirect = useCallback(async (options?: NavigateOptions) => {
    const length = user?.leagues.length ?? 0
    try {
      if (league) {
        navigate(`/${league}/home`, options)
        return
      }

      if (activeLeague) {
        navigate(`/${activeLeague}/home`, options)
        return
      }

      if (length > 1) {
        navigate('/landing', options)
        return
      } else if (length === 1) {
        setActiveLeague(user?.leagues[0])
        navigate(`/${user?.leagues[0] ?? 'undefined'}/home`, options)
        return
      }

      navigate('/undefined/home', options)
    } catch (e) {
      await logOut()
      navigate('/auth/sign-in', options)
    }
  }, [activeLeague, league, navigate, setActiveLeague, user?.leagues])

  return redirect
}
