import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Divider, FormControl, Typography } from '@mui/material'

import { logIn } from 'utils/auth'
import { LinkButton, RequiredTextField } from 'components/common'
import { useAuthState } from 'utils/hooks/useAuthState'
import { LoadingButton } from '@mui/lab'
import { USER_NAME_LOCAL_STORAGE_KEY } from 'utils/constants/constants'
import { getLocal, setLocal } from 'utils/localStorage'
import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'
import LoadingSpinner from 'components/common/LoadingSpinner'

import styles from 'pages/auth/sign-in.module.scss'

const SignIn: React.FC = () => {
  const savedUserName = useMemo<string>(() => getLocal(USER_NAME_LOCAL_STORAGE_KEY) ?? '', [])

  const auth = useAuthState()
  const redirect = useRedirectToHome()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams({ successReset: 'false', redirectUrl: '' })
  const [email, setEmail] = useState<string>(savedUserName)
  const [password, setPassword] = useState('')
  const [errorText, setErrorText] = useState('')
  const [loading, setLoading] = useState(false)

  const handleRedirectAfterLogin = useCallback(async () => {
    const redirectUrl = searchParams.get('redirectUrl')
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true })
    } else {
      await redirect({ replace: true })
    }
  }, [navigate, redirect, searchParams])

  useEffect(() => {
    if (auth?.user?.id) {
      void handleRedirectAfterLogin()
    }
  }, [auth?.user?.id, handleRedirectAfterLogin])

  function handleLogin (): void {
    setLoading(true)
    logIn(email.toLowerCase().trim(), password)
      .then(() => {
        setLocal(USER_NAME_LOCAL_STORAGE_KEY, email)
        void handleRedirectAfterLogin()
      })
      .catch((error): void => {
        setErrorText(error?.message?.replace('Firebase: ', '')?.replace('-', ' ')?.replace('auth/', ''))
        setLoading(false)
      })
  }

  return (
    <>
      <PageTitle title='Sign in' />
      {auth.loading && <LoadingSpinner />}
      {!auth.loading && !auth.authenticated && (
        <TopNavigation>
          <section className={styles.container}>
            {searchParams.get('successReset') === 'true' && <p style={{ color: 'green' }}>Successfully reset password.</p>}
            <Typography variant='h5'>Log in to RefReport</Typography>
            <form>
              <FormControl className={styles.container}>
                <RequiredTextField label='E-mail' type='email' defaultValue={savedUserName} autoFocus onChange={(event) => { setEmail(event.target.value) }} />
                <RequiredTextField
                  label='Password'
                  type='password'
                  onChange={(event) => { setPassword(event.target.value) }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleLogin()
                    }
                  }}
                />
                <LoadingButton variant="contained" onClick={handleLogin} loading={loading}>Log in</LoadingButton>
              </FormControl>
            </form>
            <section style={{ display: 'flex', gap: '1em', alignItems: 'center', textAlign: 'center', flexDirection: 'row', margin: 'auto' }}>
              <LinkButton to='/auth/sign-up'>Sign up</LinkButton>
              <Divider orientation='vertical' flexItem />
              <LinkButton to='/auth/reset'>Reset password</LinkButton>
            </section>

            {errorText && <p style={{ color: 'red' }}>{errorText}</p>}
          </section>
        </TopNavigation>
      )}
    </>
  )
}
export default SignIn
