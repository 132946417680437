import { type BaseSchema, type League } from 'utils/constants/constants'
import { getReport, saveReport } from 'utils/api/api'
import { useAuthState } from 'utils/hooks/useAuthState'
import { useEffect, useState } from 'react'
import usePromise from 'react-use-promise'
import { useDebouncedCallback } from 'use-debounce'

interface UseReportType<T> {
  report: T | undefined
  loading: boolean
  error: Error | undefined
  update: React.Dispatch<React.SetStateAction<T>>
  save: () => Promise<void>
  submit: () => Promise<void>
  saving: boolean
}

export const useReport = <T extends BaseSchema>(league: League, id: string, defaultReport: T): UseReportType<T> => {
  const [localReport, setLocalReport] = useState<T>(defaultReport)
  const [saving, setSaving] = useState(false)
  const auth = useAuthState()
  const [, error, state] = usePromise(async () => {
    if (id && auth?.user?.id) {
      const report = await getReport<T>(league, id, auth?.user?.id)
      setLocalReport(report)
    }
  }, [auth?.user?.id, league, id])

  const saveProgress = useDebouncedCallback(async () => {
    await save()
  }, 1000)

  useEffect(() => {
    if (localReport) {
      void saveProgress()
    }
  }, [localReport, saveProgress])

  async function save () {
    setSaving(true)
    try {
      if (localReport) {
        await saveReport<T>(league, id, localReport, auth?.user?.id)
      }
    } finally {
      setSaving(false)
    }
  }

  async function submit () {
    setSaving(true)
    try {
      if (localReport) {
        await saveReport<T>(league, id, { ...localReport, editable: false }, auth?.user?.id)
      }
    } finally {
      setSaving(false)
    }
  }

  return { report: localReport, loading: state === 'pending' || !id || !auth?.user?.id, error, update: setLocalReport, save, saving, submit }
}
