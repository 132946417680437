import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { PageTitleContext } from 'utils/context/PageTitleContext'

const PageTitleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>()

  const setTitle = useCallback((title: string) => {
    setPageTitle(title)
  }, [])

  const title = useMemo(() => pageTitle, [pageTitle])

  useEffect(() => {
    if (title) {
      document.title = `RefReport - ${title}`
    } else {
      document.title = 'RefReport'
    }
  }, [title])

  return (
    <PageTitleContext.Provider value={{ setTitle, title }}>
      {children}
    </PageTitleContext.Provider>
  )
}
export default PageTitleProvider
