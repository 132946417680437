import React, { type ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider
} from '@mui/material'
import { LoadingButton } from '@mui/lab'

interface ConfirmDialogProps {
  open: boolean
  title: string
  body: ReactNode
  onOk: () => void
  onCancel: () => void
  confirmColor?: 'error' | 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning'
  loading?: boolean
  confirmText?: string
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, body, onOk, onCancel, confirmColor = 'success', loading, confirmText = 'Confirm' }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth keepMounted={false}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton loading={loading} color={confirmColor} variant="contained" onClick={onOk}>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
