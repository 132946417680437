import { type League } from 'utils/constants/constants'
import { useApi } from 'utils/hooks/useApi'
import { getLeagueUsers } from 'utils/api/api'

export const useLeagueUsers = (league: League) => {
  const { data, loading, error, trigger } = useApi(async () => {
    return await getLeagueUsers(league)
  }, [league])

  return { data, loading, error, trigger }
}
