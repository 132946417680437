import { type ThemeOptions } from '@mui/material/styles'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#3d9970'
    },
    secondary: {
      main: '#fbf9fb'
    }
  }
}
