import { useApi } from 'utils/hooks/useApi'
import { getLeagueDocuments } from 'utils/api/api'
import { type League } from 'utils/constants/constants'

export const useLeagueDocuments = (league: League) => {
  const result = useApi(async () => {
    const documents = await getLeagueDocuments(league)
    return { documents }
  }, [league])

  return result
}
