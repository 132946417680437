import React from 'react'
import { useSearchParams } from 'react-router-dom'

import ResetPassword from 'components/auth/actions/ResetPassword'

import styles from 'pages/auth/action.module.scss'
import TopNavigation from 'components/common/navigation/TopNavigation'
import PageTitle from 'components/common/PageTitle'

enum Modes {
  RESET_PASSWORD = 'resetPassword',
  RECOVER_EMAIL = 'recoverEmail',
  VERIFY_EMAIL = 'verifyEmail'
}

const Action: React.FC = () => {
  const [searchParams] = useSearchParams({ mode: '', oobCode: '' })

  return (
    <>
      <PageTitle title={searchParams.get('mode') === Modes.RESET_PASSWORD ? 'Set Password' : ''} />
      <TopNavigation>
        <section className={styles.container}>
          {searchParams.get('mode') === Modes.RESET_PASSWORD && <ResetPassword oobCode={searchParams.get('oobCode')} />}
        </section>
      </TopNavigation>
    </>
  )
}
export default Action
