import { Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Cancel } from '@mui/icons-material'

import UploadImage from 'components/common/UploadImage'
import { type League } from 'utils/constants/constants'
import { type MASLReportSchema } from 'utils/constants/masl'
import { Section } from 'components/common'
import OpenImageButton from 'components/common/OpenImageButton'

interface MASLUploadWorksheetProps {
  id: string
  worksheetImageUrl?: string | null
  league: League
  onStateChange: (key: keyof MASLReportSchema, value: string | null) => void
  onLoadingChange?: (loading: boolean) => void
}

const MASLUploadWorksheet: React.FC<MASLUploadWorksheetProps> = ({ id, worksheetImageUrl, league, onStateChange, onLoadingChange }) => {
  const [uploadingWorksheet, setUploadingWorksheet] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  useEffect(() => {
    onLoadingChange?.(uploadingWorksheet)
  }, [onLoadingChange, uploadingWorksheet])

  return (
    <>
      <Typography marginBottom={'8px'}>Upload 4th Official&apos;s Worksheet</Typography>
      <Section>
        {!worksheetImageUrl && !uploadingWorksheet && <UploadImage
          fileName='worksheet'
          league={league}
          reportId={id}
          onUpload={(url) => {
            setUploadingWorksheet(false)
            onStateChange('worksheetImageUrl', url)
          }}
          onUploadStart={() => {
            setUploadError(false)
            setUploadingWorksheet(true)
          }}
          onError={() => {
            setUploadError(true)
          }}
        />}
        {worksheetImageUrl && !uploadingWorksheet && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <CheckCircleIcon color='success' />
              <OpenImageButton filePath={worksheetImageUrl}>Worksheet Image</OpenImageButton>
            </section>
            <Button color='warning' onClick={() => { onStateChange('worksheetImageUrl', null) }}>Delete and upload new image</Button>
          </>
        )}
        {uploadingWorksheet && (
          <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
            <CircularProgress size={24} />
            <>Uploading Image...</>
          </section>
        )}
        {!worksheetImageUrl && uploadError && (
          <>
            <section style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5em' }}>
              <Cancel color='error' />
              <>Error Uploading Image</>
            </section>
          </>
        )}
      </Section>
    </>
  )
}
export default MASLUploadWorksheet
