import React, { type CSSProperties, type SyntheticEvent } from 'react'
import { Autocomplete, type AutocompleteChangeReason } from '@mui/material'

import { RequiredTextField } from 'components/common'

export const penalties = [
  // Blues
  { category: 'Blue', code: 'B1', description: 'Too Many Men (gains an advantage or interferes with play, 3.4)' },
  { category: 'Blue', code: 'B2', description: 'Four Fouls by a player in a half (12.4) ' },
  { category: 'Blue', code: 'B3', description: 'Any foul committed by Goalkeeper on Shootout' },
  { category: 'Blue', code: 'B4', description: 'Intentional handball by Goalkeeper outside of his penalty area (12.12.D)' },
  { category: 'Blue', code: 'B5', description: 'Goalkeeper striking by throwing the ball at or violently pushing opponent while in control of the ball ' },
  { category: 'Blue', code: 'B6', description: 'Kick (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B7', description: 'Trip (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B8', description: 'Reckless Tackle (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B9', description: 'Two-Footed Tackle' },
  { category: 'Blue', code: 'B10', description: 'Jump (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B11', description: 'Charging from behind or Charging Violently or Dangerously (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B12', description: 'Striking (Severe or blatant in nature)' },
  { category: 'Blue', code: 'B13', description: 'Contact Above the Shoulder/Elbow (Severe or blatant in nature)' },
  { category: 'Blue', code: 'B14', description: 'Hold (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B15', description: 'Push (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B16', description: 'Handling (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B17', description: 'Boarding (Severe or blatant in nature)' },
  { category: 'Blue', code: 'B18', description: 'Dangerous play (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B19', description: 'Obstruction (Severe, tactical, or blatant in nature)' },
  { category: 'Blue', code: 'B20', description: 'Shootout: Denies an Obvious Goal Scoring Opportunity (14.7)' },
  { category: 'Blue', code: 'B21', description: 'Unsportsmanlike Conduct (misconduct during mass confrontations or deadball situations)' },

  // Yellows
  { category: 'Yellow', code: 'Y1', description: 'Dissent', fine: '$50, $100, $150, (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y2', description: 'Bench Misconduct (not identifiable) assessed against coach (no one serves 5-minutes)(12.8.B)' },
  { category: 'Yellow', code: 'Y3', description: 'Game Delay - Team (assessed against Coach (no one serves 5-minutes) (12.8.C)' },
  { category: 'Yellow', code: 'Y4', description: 'Delay of Game after Time Penalty - failing to immediately report to the penalty box (12.8.E)', fine: '$100 (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y5', description: 'Ball thrown/kicked off field following goal (12.8.D)' },
  { category: 'Yellow', code: 'Y6', description: 'Major Penal Penalty (12.8.F)' },
  { category: 'Yellow', code: 'Y7', description: 'Embellishing or Faking a foul after Team Warning (12.8.G)', fine: '$50, $100, $150, (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y8', description: 'Referee Crease Violation (7.3b, 12.8.H)', fine: '$200 (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y9', description: 'Penalty Box Decorum (12.11.F)', fine: '$100 (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y10', description: 'Injured player illegal return (3.10 & 3.12)' },
  { category: 'Yellow', code: 'Y11', description: 'Penalty box exit prior to time expiration (12.9.A)' },
  { category: 'Yellow', code: 'Y12', description: 'Shootout or Penalty Kick violation following Team Warning (12.9.A & 14.8)' },
  { category: 'Yellow', code: 'Y13', description: 'Goalkeeper joining in an altercation (leaving his penalty area) 12.12.F' },
  { category: 'Yellow', code: 'Y14', description: 'Delay of Game Violation (Restarts) (following Team Warning) 12.9.B' },
  { category: 'Yellow', code: 'Y15', description: 'Encroachment (following Team Warning) (13.4.L)' },
  { category: 'Yellow', code: 'Y16', description: 'Reentry failing adjustment dangerous equipment (4.5)' },
  { category: 'Yellow', code: 'Y17', description: 'Severe Unsporstmanlike Manner', fine: '$200 (add $50 each occurrence)' },
  { category: 'Yellow', code: 'Y18', description: 'Encroachment on Shootout after a Team Warning (12.9.A & 14.8.F)' },
  { category: 'Yellow', code: 'Y19', description: 'Entering spectator area', fine: '$100' },

  // Reds
  { category: 'Red', code: 'R1', description: 'Violent Conduct or Serious Foul Play', fine: 'DRC' },
  { category: 'Red', code: 'R2', description: 'Offensive, insulting, obscene or abusive language and/or gestures', fine: 'DRC' },
  { category: 'Red', code: 'R3', description: 'Headbutt', fine: 'DRC' },
  { category: 'Red', code: 'R4', description: 'Third man into an altercation', fine: 'DRC' },
  { category: 'Red', code: 'R5', description: 'First off bench joining an altercation', fine: 'DRC' },
  { category: 'Red', code: 'R6', description: 'Leaving the penalty box to engage in an altercation or dissent', fine: 'DRC' },
  { category: 'Red', code: 'R7', description: 'Spitting on or at opponent or official', fine: 'DRC' },
  { category: 'Red', code: 'R8', description: 'Accumulation of 3rd penalty', fine: 'DRC' },
  { category: 'Red', code: 'R9', description: 'Six Fouls in a game (12.5) (NO POWER PLAY)', fine: 'DRC' }
]

export type PenaltyDescription = typeof penalties[number]

interface PenaltyAutocompleteInputProps {
  className?: string | undefined
  style?: CSSProperties
  value?: PenaltyDescription
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: {
      description: string
      code: string
      category: string
      fine?: string
    },
    reason: AutocompleteChangeReason
  ) => void
  disabled?: boolean
  error?: boolean
}

export const PenaltyAutocompleteInput: React.FC<PenaltyAutocompleteInputProps> = ({ value, onChange, disabled, error, style, className }) => {
  return (
    <Autocomplete
      className={className}
      value={value}
      onChange={onChange}
      options={penalties}
      getOptionLabel={(option) => `${option.code} - ${option.description}`}
      groupBy={(option) => option.category}
      renderInput={(params) => (
        <RequiredTextField style={style} {...params} label='Penalty' disabled={disabled} error={error} />
      )}
      disableClearable
      disabled={disabled}
    />
  )
}
