import { type League } from 'utils/constants/constants'
import { type MASLPlayerInfo, type Penalty } from 'utils/constants/masl'
import { post } from 'utils/api/api'

type ExportablePenalty = Penalty & { date?: string, season?: string, gameId?: string, gameNumber?: string }

export async function getMASLPenalties (league: League, startDate: string, endDate: string) {
  const response = await post<{ penalties?: ExportablePenalty[] }>('https://getmaslpenalties-jdkif3ehcq-uc.a.run.app', { startDate, endDate, league })
  return response
}

export async function getMASLPlayerList () {
  const response = await post<MASLPlayerInfo[]>('https://getmaslplayerlist-jdkif3ehcq-uc.a.run.app', {})
  return response
}

export async function setMASLPlayerList (league: League, players: Array<Partial<MASLPlayerInfo>>) {
  const response = await post('https://setmaslplayerlist-jdkif3ehcq-uc.a.run.app', { league, players })
  return response
}
