import { createContext } from 'react'

interface PageTitleContextProps {
  setTitle: (title: string) => void
  title: string | undefined
}

export const PageTitleContext = createContext<PageTitleContextProps>({
  setTitle: () => {},
  title: ''
})
