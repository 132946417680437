import React from 'react'

import LandingView from 'components/landing/Landing'
import { RequireAuth } from 'components/auth/RequireAuth'
import PageTitle from 'components/common/PageTitle'
import TopNavigation from 'components/common/navigation/TopNavigation'

const Landing: React.FC = () => {
  return (
    <>
      <PageTitle title='Leagues' />
      <RequireAuth ignoreAuthRedirect>
        <TopNavigation>
          <LandingView />
        </TopNavigation>
      </RequireAuth>
    </>
  )
}
export default Landing
