import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import Action from 'pages/auth/action'
import Reset from 'pages/auth/reset'
import SignUp from 'pages/auth/sign-up'
import SignIn from 'pages/auth/sign-in'
import JoinLeague from 'pages/auth/join-league'

export const authRoutes = [
  <Route key='auth' path={'auth'} element={<Navigate to="/auth/sign-in" replace />} />,
  <Route key='sign-in' path={'sign-in'} element={<Navigate to="/auth/sign-in" replace />} />,
  <Route key='auth/sign-in' path={'auth/sign-in'} element={<SignIn />} />,
  <Route key='auth/reset' path={'auth/reset'} element={<Reset />} />,
  <Route key='auth/action' path={'auth/action'} element={<Action />} />,
  <Route key='auth/sign-up' path={'auth/sign-up'} element={<SignUp />} />,
  <Route key='auth/join-league' path={'auth/join-league'} element={<JoinLeague />} />
]
