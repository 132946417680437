import React from 'react'

import { RequireAuth } from 'components/auth/RequireAuth'
import { MASLReport } from 'components/masl/report/MASLReport'
import { type League } from 'utils/constants/constants'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/menu/MASLNavigationMenu'
import PageTitle from 'components/common/PageTitle'

const Report: React.FC<{ league: League.MASL | League.M2 | League.M3 }> = ({ league }) => {
  return (
    <RequireAuth>
       <PageTitle title={`${league.toLocaleUpperCase()} - Report`} />
      <DrawerNavigation menu={<MASLNavigationMenu league={league} />}>
        <MASLReport league={league} />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default Report
