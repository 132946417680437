import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Pagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { BasicTextField, Section } from 'components/common'
import { type League } from 'utils/constants/constants'
import { useAuthState } from 'utils/hooks/useAuthState'
import { Permission } from 'utils/providers/AuthProvider'
import { useLeagueReports } from 'utils/hooks/useLeagueReports'
import { useDebounce } from 'use-debounce'
import ConfirmDialog from 'components/common/modal/ConfirmDialog'
import { deleteReport } from 'utils/api/api'
import { DateFilter } from 'utils/constants/masl'
import { Clear } from '@mui/icons-material'
import LocaleTime from 'components/common/LocaleTime'

const PAGE_RESULTS_COUNT = 10

interface MASLHomeProps {
  league: League.MASL | League.M2 | League.M3
}

export const MASLHome: React.FC<MASLHomeProps> = ({ league }) => {
  const [searchQuery, setSearchQuery] = useDebounce<string>('', 500)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const [deleteReportId, setDeleteReportId] = useState<string>()
  const [deleteInProgress, setDeleteInProgress] = useState(false)

  function handleDeleteClick (reportId: string) {
    setDeleteReportId(reportId)
    setShowDeleteConfirmDialog(true)
  }

  function handleDelete () {
    setDeleteInProgress(true)
    if (league && deleteReportId && user?.id) {
      deleteReport(league, deleteReportId, user.id).then(() => {
        setDeleteInProgress(false)
        window.location.reload()
      }).catch((e) => {
        console.error(e)
      })
    }
  }

  const searchBar = useRef<HTMLInputElement>(null)

  const { user } = useAuthState()
  const navigate = useNavigate()
  const page = useMemo(() => parseInt(searchParams.get('page') ?? '0'), [searchParams])
  const date: DateFilter = useMemo(() => searchParams.get('date') as DateFilter ?? DateFilter.FUTURE, [searchParams])
  const urlSearchQuery = useMemo(() => searchParams.get('query') ?? '', [searchParams])
  const { reports: matches = [], length, loading = true, error } = useLeagueReports(
    { league, page, count: PAGE_RESULTS_COUNT, searchQuery, dateFilter: date },
    [page, PAGE_RESULTS_COUNT, searchQuery, league, date]
  )

  useEffect(() => {
    if (urlSearchQuery) {
      setSearchQuery(urlSearchQuery)
      if (searchBar.current) searchBar.current.value = urlSearchQuery
    }
  }, [setSearchParams, setSearchQuery, urlSearchQuery])

  useEffect(() => {
    if (searchQuery) {
      setSearchParams((prev) => ({ date, page: '0' }))
    }
  }, [searchQuery, setSearchParams, urlSearchQuery, date])

  function handleSearchChange (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchQuery(event.target.value)
  }

  function handleDateFilterChange (event: SelectChangeEvent<DateFilter>) {
    setSearchParams(prev => ({ page: '0', date: event.target.value }))
  }

  function handleClearFilter (clearDateFilter?: boolean) {
    setSearchQuery('')
    if (clearDateFilter) {
      setSearchParams({ page: '0', date: DateFilter.FUTURE })
    } else {
      setSearchParams(prev => ({ page: '0', date }))
    }
    if (searchBar.current) searchBar.current.value = ''
  }

  return (
    <>
      <Section style={{ marginTop: '1em' }}>
        <BasicTextField
          inputRef={searchBar}
          placeholder='Search...'
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => { handleClearFilter() }}
                  edge="start"
                  disabled={!searchQuery}
                >
                  <Clear style={{ visibility: searchQuery ? 'visible' : 'hidden' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <FormControl>
          <InputLabel>Date</InputLabel>
          <Select
            value={date}
            label='Date'
            onChange={handleDateFilterChange}
          >
            <MenuItem value={DateFilter.ALL} selected>All Games</MenuItem>
            <MenuItem value={DateFilter.FUTURE}>Upcoming Games</MenuItem>
            <MenuItem value={DateFilter.PAST}>Past Games</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant='text'
          onClick={() => { handleClearFilter(true) }}
        >Clear filters</Button>
      </Section>
      <Section>
        {matches.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align='left'>Game Number</TableCell>
                  <TableCell align='left'>Date</TableCell>
                  <TableCell align='left'>Time</TableCell>
                  <TableCell align='left'>Season</TableCell>
                  <TableCell align="left">Home Team</TableCell>
                  <TableCell align="left">Away Team</TableCell>
                  <TableCell align="left">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matches
                  .map((match) => (
                    <TableRow
                      key={match.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                          {user?.permissions[league].some(p => p === Permission.ADMIN || p === Permission.EDITOR) && match.editable && (
                            <Button disabled={!match.editable} onClick={() => { navigate(`/${league}/report/${match?.id ?? ''}`) } }>Edit</Button>
                          )}
                          {user?.permissions[league].some(p => p === Permission.ADMIN || p === Permission.EDITOR || p === Permission.VIEWER) && !match.editable && (
                            <Button onClick={() => { navigate(`/${league}/view/${match?.id ?? ''}`) } }>View</Button>
                          )}
                          {user?.permissions[league].includes(Permission.ADMIN) && (
                            <Button color='warning' onClick={() => { handleDeleteClick(match?.id) } }>Delete</Button>
                          )}
                          {user?.permissions[league].includes(Permission.ADMIN) && !match.editable && (
                            <Button onClick={() => { navigate(`/${league}/report/${match?.id ?? ''}`) } }>Unsubmit</Button>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">{match.gameNumber}</TableCell>
                      <TableCell align='left'>{match.date && (match.date.length > 0) && new Date(match?.date ?? '').toLocaleDateString()}</TableCell>
                      <TableCell align="left"><LocaleTime date={match.date} time={match.gameTime} /></TableCell>
                      <TableCell align="left">{match.season}</TableCell>
                      <TableCell align="left">{match.homeTeam}</TableCell>
                      <TableCell align="left">{match.awayTeam}</TableCell>
                      <TableCell align="left">{match.editable ? 'Open' : 'Submitted'}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {loading && (
          <section
            style={{
              width: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              display: 'flex'
            }}
          >
            <CircularProgress />
          </section>
        )}
        {error && <h1 style={{ textAlign: 'center' }}>Error loading reports</h1>}
        <Pagination
          count={length ? Math.ceil(length / PAGE_RESULTS_COUNT) : 0}
          page={page + 1}
          variant="outlined"
          shape="rounded"
          onChange={(event, p) => { setSearchParams(prev => ({ date, page: (p - 1).toString() })) }}
        />
        <ConfirmDialog
          open={showDeleteConfirmDialog}
          onOk={() => { handleDelete() } }
          onCancel={() => { setShowDeleteConfirmDialog(false) } }
          title='Confirm deletion'
          body={`Confirm you want to delete report ${deleteReportId ?? ''}`}
          loading={deleteInProgress}
          confirmColor='error'
        />
      </Section>
    </>
  )
}
