import React from 'react'

import { RequireAuth } from 'components/auth/RequireAuth'
import { League } from 'utils/constants/constants'
import { MASLUploadPlayerList } from 'components/masl/upload-player-list/MASLUploadPlayerList'
import DrawerNavigation from 'components/common/navigation/DrawerNavigation'
import MASLNavigationMenu from 'components/masl/menu/MASLNavigationMenu'
import PageTitle from 'components/common/PageTitle'

const UploadPlayerList: React.FC = () => {
  return (
    <RequireAuth>
      <PageTitle title='Upload Player List' />
      <DrawerNavigation menu={<MASLNavigationMenu league={League.MASL} />}>
        <MASLUploadPlayerList league={League.MASL} />
      </DrawerNavigation>
    </RequireAuth>
  )
}
export default UploadPlayerList
