import React, { useMemo } from 'react'
import { Button, Autocomplete } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { useConfirm } from 'material-ui-confirm'

import { type MASLTeam, type MASLReportSchema, type Penalty, periods, type M2Team, type MASLPlayerInfo, MASL_TEAMS, M2_TEAMS, M3_TEAMS, type MASLCoach, MASLPlayerType, GameType, extraTimePeriods } from 'utils/constants/masl'
import { PenaltyAutocompleteInput } from 'components/masl/inputs/PenaltyAutocompleteInput'
import { validate } from 'utils'
import { BasicTextField } from 'components/common'
import { League } from 'utils/constants/constants'
import { PlayerAutocompleteInput } from 'components/masl/inputs/PlayerAutocompleteInput'
import { CoachAutocompleteInput } from 'components/masl/inputs/CoachAutocompleteInput'
import ClockTimeInput from 'components/common/ClockTimeInput'

import style from './MASLPenalties.module.scss'

function getTeams (league: League): string[] {
  if (league === League.MASL) return MASL_TEAMS as string[]
  if (league === League.M2) return M2_TEAMS as string[]
  if (league === League.M3) return M3_TEAMS as string[]

  return []
}

interface PenaltyFragmentProps {
  homeTeam: MASLTeam | string
  awayTeam: MASLTeam | string
  onPenaltyChanged: (penalty: Penalty) => void
  onPenaltyRemoved: (id: string) => void
  league: League.MASL | League.M2 | League.M3
  gameType?: GameType
  penalty: Penalty
}

const PenaltyFragment: React.FC<PenaltyFragmentProps> = ({ homeTeam, awayTeam, onPenaltyChanged, onPenaltyRemoved, league, penalty, gameType }) => {
  const periodOptions = useMemo(() => gameType === GameType.EXTRA_TIME_PERIOD ? extraTimePeriods : periods, [gameType])

  function handlePenaltyPlayerNameChange (event: React.SyntheticEvent<Element, Event>, value: string | Partial<MASLPlayerInfo>) {
    onPenaltyChanged({ ...penalty, name: typeof value === 'string' ? value : value.playerName })
  }

  function handlePenaltyCoachNameChange (event: React.SyntheticEvent<Element, Event>, value: string | Partial<MASLCoach>) {
    onPenaltyChanged({ ...penalty, name: typeof value === 'string' ? value : value.name })
  }

  return (
    <div
      key={penalty.id}
      className={style.tableRow}
    >
      <div className={style.tableCell}>
        <Autocomplete
          defaultValue={MASLPlayerType.Player}
          className={style.tableCellInput}
          placeholder='Player or Coach?'
          value={penalty.playerType ?? MASLPlayerType.Player}
          onChange={(event, value) => { onPenaltyChanged({ ...penalty, playerType: value }) }}
          options={[MASLPlayerType.Player, MASLPlayerType.Coach, MASLPlayerType.Team]}
          renderInput={(params) => (
            <BasicTextField required {...params}
              label='Player or Coach?'
              error={!validate(penalty.playerType)}
            />
          )}
          disableClearable
        />
      </div>
      {league === League.MASL && penalty.playerType === MASLPlayerType.Player && <PlayerAutocompleteInput className={style.tableCell} defaultValue={penalty.name} league={League.MASL} onChange={handlePenaltyPlayerNameChange} error={!validate(penalty.name)} />}
      {league === League.MASL && penalty.playerType === MASLPlayerType.Coach && <CoachAutocompleteInput className={style.tableCell} defaultValue={penalty.name} league={League.MASL} onChange={handlePenaltyCoachNameChange} error={!validate(penalty.name)} />}
      {league !== League.MASL && <BasicTextField className={style.tableCell} placeholder='Last name, First name' required value={penalty.name} label={`${penalty.playerType ?? MASLPlayerType.Player} Name`} onChange={(event) => { onPenaltyChanged({ ...penalty, name: event.target.value }) }} error={!validate(penalty.name)} />}
      {penalty.playerType === MASLPlayerType.Player && (
        <div className={style.tableCell}>
          <BasicTextField value={penalty.number} required className={style.tableCellInput} type="tel" label='Number' onChange={(event) => { onPenaltyChanged({ ...penalty, number: event.target.value }) }} error={!validate(penalty.number)} />
        </div>
      )}
      <div className={style.tableCell}>
        <Autocomplete
          value={penalty.team}
          className={style.tableCellInput}
          onChange={(event, value) => { onPenaltyChanged({ ...penalty, team: value }) }}
          options={getTeams(league)?.filter(team => homeTeam.startsWith(team) || awayTeam.startsWith(team)) ?? [homeTeam, awayTeam]}
          renderInput={(params) => (
            <BasicTextField required {...params} label={'Team'} error={!validate(penalty.team)} />
          )}
          disableClearable
        />
      </div>
      <div className={style.tableCell}>
        <Autocomplete
          className={style.tableCellInput}
          value={penalty.period}
          onChange={(event, value) => {
            onPenaltyChanged({ ...penalty, period: value })
          }}
          options={periodOptions}
          renderInput={(params) => (
            <BasicTextField required {...params} label='Period' error={!validate(penalty.period)}/>
          )}
          disableClearable
        />
      </div>
      <div className={style.tableCell}>
        <ClockTimeInput
          className={style.tableCellInput}
          value={penalty.time}
          onChange={(e) => {
            onPenaltyChanged({ ...penalty, time: e.target.value })
          }}
          error={!validate(penalty.time)}
        />
      </div>
      <div className={style.tableCell}>
        <PenaltyAutocompleteInput
          value={{ code: penalty.code ?? '', description: penalty.explanation ?? '', category: penalty.category ?? '' }}
          className={style.tableCellInput}
          style={{ minWidth: 300 }}
          onChange={(event, value) => {
            onPenaltyChanged({ ...penalty, explanation: value.description, code: value.code, category: value.category, fine: value?.fine ?? '' })
          }}
          error={!validate(penalty.explanation) || !validate(penalty.code)}
        />
      </div>
      <div style={{ width: '100%' }}><Button color='error' onClick={() => { onPenaltyRemoved(penalty.id) } }>Delete</Button></div>
    </div>
  )
}

interface MASLPenaltiesProps {
  homeTeam: MASLTeam | M2Team | string
  awayTeam: MASLTeam | M2Team | string
  penalties: Penalty[] | undefined
  onStateChange: (key: keyof MASLReportSchema, value: Penalty[]) => void
  league: League.MASL | League.M2 | League.M3
  gameType?: GameType
}

const MASLPenalties: React.FC<MASLPenaltiesProps> = ({ homeTeam, awayTeam, penalties, league, onStateChange, gameType }) => {
  const confirmDialog = useConfirm()

  function createPenalty () {
    onStateChange('penalties', [...(penalties ?? []), { id: uuid(), playerType: MASLPlayerType.Player }])
  }

  function updatePenalty (p: Penalty): void {
    const editedPenalties = penalties ?? []
    const index = editedPenalties?.findIndex(pen => pen.id === p.id)
    editedPenalties[index] = p
    onStateChange('penalties', editedPenalties)
  }

  function deletePenalty (id: string): void {
    confirmDialog({
      title: 'Delete penalty',
      description: 'Do you want to delete this penalty?',
      confirmationText: 'Delete',
      confirmationButtonProps: { color: 'error' }
    })
      .then(() => {
        onStateChange('penalties', [...(penalties ?? []).filter(penalty => penalty.id !== id)])
      })
      .catch(() => {})
  }

  return (
    <>
      {(penalties?.length ?? 0) > 0 && (
        <section>
          {(penalties ?? []).map((penalty: Penalty) => (
            <PenaltyFragment
              key={penalty.id}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              onPenaltyChanged={updatePenalty}
              onPenaltyRemoved={deletePenalty}
              league={league}
              gameType={gameType}
              penalty={penalty}
            />
          ))}
        </section>
      )}
      <Button style={{ marginLeft: 'auto' }} onClick={createPenalty} fullWidth>Add Penalty or Ejection</Button>
    </>
  )
}
export default MASLPenalties
