import React, { type ReactNode } from 'react'
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

import { type League } from 'utils/constants/constants'

interface DrawerMenuItemProps {
  league: League
  path: string
  children?: ReactNode
  icon: ReactNode
}

export const DrawerMenuItem: React.FC<DrawerMenuItemProps> = ({ league, path, children, icon }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  function navigateAction () {
    navigate(`/${league}/${path}`)
  }

  return <ListItem><ListItemButton selected={pathname.includes(path)} onClick={navigateAction}><ListItemIcon>{icon}</ListItemIcon> {children}</ListItemButton></ListItem>
}
