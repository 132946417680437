import { Link, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'

import { clearLocal } from 'utils/localStorage'
import { clearSession } from 'utils/sessionStorage'

const Error = () => {
  const error = useRouteError() as { status: string, statusText: string }
  console.error(error)

  useEffect(() => {
    clearLocal()
    clearSession()
  }, [])

  return (
    <div style={{ textAlign: 'center', padding: '1em' }}>
      <Typography variant='h2'>Oops!</Typography>
      <Typography variant='h5'>Sorry, an unexpected error has occurred.</Typography>
      <Typography variant='h5'>
        <i>{error?.status} {error?.statusText}</i>
      </Typography>
      <Typography><Link href='/'>Home</Link></Typography>
    </div>
  )
}

export default Error
