import { AppBar, Box } from '@mui/material'
import React, { type ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useAuthState } from 'utils/hooks/useAuthState'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'
import Footer from 'components/common/Footer'

const AppTitle = styled.h1`
  font-size: 36px;
  text-align: center;
  margin: 0;
  cursor: pointer;
`

interface TopNavigationProps {
  children?: ReactNode
  buttons?: ReactNode[]
}

const TopNavigation: React.FC<TopNavigationProps> = ({ children, buttons }) => {
  const auth = useAuthState()
  const redirect = useRedirectToHome()
  const navigate = useNavigate()
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          ml: { sm: '240px' }
        }}
      >
        <section style={{ display: 'flex', alignItems: 'center', gap: 8, padding: 4, marginLeft: '0.5em' }}>
          {auth?.user?.id
            ? (<AppTitle onClick={() => { void redirect() }}>RefReport</AppTitle>)
            : (<AppTitle onClick={() => { navigate('/') }}>RefReport</AppTitle>)
          }
          <div style={{ marginLeft: 'auto', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
            {buttons}
          </div>
        </section>
      </AppBar>
      <Box marginTop={'50px'} minHeight={'80vh'}>
        {children}
      </Box>
      <Footer fullWidth />
    </>
  )
}
export default TopNavigation
